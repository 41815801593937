import React from 'react'
// import $ from 'jquery'
import anime from 'animejs'
import './animation_text.css'
export default class Animation_Text extends React.Component{
    componentDidMount(){
        // Wrap every letter in a span
// $('.ml9_new .letter_animations').each(function(){
//     $(this).html($(this).text().replace(/([^\x00-\x80]|\w)/g, "<span class='letter_animations'>$&</span>"));
//   });
  
  anime.timeline({loop: true})
    .add({
      targets: '.ml9_new .letter_animations',
      scale: [0, 1],
      duration: 1500,
      elasticity: 600,
      delay: function(el, i) {
        return 45 * (i+1)
      }
    }).add({
      targets: '.ml9_new',
      opacity: 0,
      duration: 500,
      easing: "easeOutExpo",
      delay: 2000
    });
    }
    render(){
        return(
            <div><h1 class="ml9_new">
            <span class="text-wrapper_new_animations">
              <span class="letter_animations">Contact Us...</span>
              {/* <span class="letter_animations">Coffee </span> */}
            </span>
          </h1>
          </div>
                 )
    }
}