
import React  from 'react'
import './portfolio.css'
// import AOS from 'aos'
import Extra_Videos from './extra_video.js'
import Web_Design from './img_new/videos/img1/ux1.png'
import Web_Development from './img_new/videos/img1/web-app1.png'
import Ecommerce_img from './img_new/videos/img1/ecommerce1.png'
import Mobile_img from './img_new/videos/img1/mobile app1.png'
import VR_img from './img_new/videos/img1/ar1.png'
import Digital_img from './img_new/videos/img1/seo1.png'
import { BrowserRouter as Router, Switch, Route,Link } from 'react-router-dom';
export default class Our_capabilities extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          reality: {},
          mobile: {},
          application: {},
          ecommerce: {},
          design: {},
          digital: {}
        };
      }
    
      componentDidMount() {
        const reality = {
          title: "Augmented Reality / Virtual Reality Services",
          description: "Leading the charge in augmented reality (AR) and virtual reality (VR) solutions, delivering cutting-edge, immersive experiences that solve complex business challenges and elevate user engagement. By integrating advanced technologies like AI, these forward-thinking solutions ensure businesses stay ahead in an ever-evolving competitive landscape.",
        };
    
        const mobile = {
          title: "Mobile App Development",
          description: "We specialize in creating innovative mobile applications that connect your business with customers anytime, anywhere. By prioritizing intuitive design and seamless functionality, our apps deliver exceptional user experiences on both iOS and Android platforms. Whether you're looking to enhance engagement or stand out in a competitive market, our tailored mobile solutions ensure your business thrives.",
        };
    
        const application = {
          title: "Web Application Development",
          description: "Delivering scalable, secure, and intuitive web applications tailored to meet unique business needs. Expert developers utilize advanced technologies to create solutions that drive growth and enhance online presence.",
        };
    
        const ecommerce = {
          title: "E-commerce Solutions",
          description: "Empower your business to thrive in the digital marketplace with our advanced eCommerce solutions. Designed to unlock your full potential, our tailored platforms help you tap into the vast online market, enhance customer engagement, and drive increased sales.",
        };
    
        const design = {
          title: "UI / UX Design",
          description: "We excel in crafting visually stunning and highly functional user interfaces by carefully planning layouts, creating engaging content, and designing captivating visuals. Leveraging the latest technologies, our expertise in web design and our strong client relationships showcase our commitment to delivering exceptional and impactful digital experiences.",
        };
    
        const digital = {
          title: "Digital Marketing",
          description: " Harness the power of digital marketing with our expert strategies designed to elevate your brand’s visibility and engagement. We offer comprehensive digital marketing services, including SEO, PPC, and social media management, to drive traffic, generate leads, and build lasting customer relationships.",
        };
    
        this.setState({ reality, mobile, application, ecommerce, design, digital });
      }   
    render(){
        const{ reality, mobile, application, ecommerce, design, digital } =this.state
        return(
            <div>
                <section className="bg_our_capa">
                <div className={'marg_all_heading'}>
                <h2 className="about_ser_new_tags">Recognized experts in...</h2>
                        <span className="about_bor_bot_serv"></span>
                        </div>
             <div class="container">
                 <div class="row our-service">
                 <div class="col-md-4">
                            <div class="service-box custom_hover">
                                <div class="service-img">
                                    <img data-aos="zoom-in" src={VR_img} alt="not found" />
                                </div>
                                <div class="service-content">
                                    <div class="service-title">
                                        <h4 data-aos="fade-up-right"><a >{reality.title}</a></h4>
                                    </div>
                                    <p className={'hegiht_newcapabilities'} data-aos="fade-up-right">{reality.description}</p>
                                         <Link to="/our-service/#AR"  target="_top"  class="box_btn">read more</Link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="service-box custom_hover">
                                <div class="service-img">
                                    <img data-aos="zoom-in" src={Mobile_img} alt="not found" />
                                </div>
                                <div class="service-content">
                                    <div class="service-title">
                                        <h4 data-aos="fade-up-right"><a >{mobile.title}</a></h4>
                                    </div>
                                    <p className={'hegiht_newcapabilities'} data-aos="fade-up-right">{mobile.description}</p>
                                        <Link to="/our-service/#Mobile" target="_top"  class="box_btn">read more</Link>
                                </div>
                            </div>
                        </div>
                     <div class="col-md-4">
                         <div class="service-box custom_hover">
                             <div class="service-img">
                                 <img data-aos="zoom-in" src={Web_Development} alt="not found" />
                             </div>
                             <div class="service-content">
                                 <div class="service-title">
                                     <h4 data-aos="fade-up-right"><a  >{application.title}</a></h4>
                                 </div>
                                 <p className={'hegiht_newcapabilities'} data-aos="fade-up-right">{application.description}</p>
                                     <Link to="/our-service/#webapps" target="_top" class="box_btn">read more</Link>
                             </div>
                         </div>
                     </div>
                     
                 </div>
                 <br></br><br></br>
                 <div class="row our-service ">
                 <div class="col-md-4">
                         <div class="service-box custom_hover">
                             <div class="service-img">
                                 <img data-aos="zoom-in" src={Ecommerce_img} alt="not found" />
                             </div>
                             <div class="service-content">
                                 <div class="service-title">
                                     <h4 data-aos="fade-up-right"><a >{ecommerce.title}</a></h4>
                                 </div>
                                 <p className={'hegiht_newcapabilities'} data-aos="fade-up-right">{ecommerce.description}</p>
                                     <Link to="/our-service/#Ecommerce" target="_top"  class="box_btn">read more</Link>
                             </div>
                         </div>
                     </div>
                 <div class="col-md-4">
                         <div class="service-box custom_hover">
                             <div class="service-img">
                                 <img data-aos="zoom-in" src={Web_Design} alt="not found" />
                             </div>
                             <div class="service-content">
                                 <div class="service-title">
                                     <h4><a  data-aos="fade-up-right">{design.title}</a></h4>
                                 </div>
                                 <p className={'hegiht_newcapabilities'} data-aos="fade-up-right">{design.description}</p>
                                     <Link to="/our-service/#webdesign" target="_top"  class="box_btn">read more</Link>
                             </div>
                         </div>
                     </div>
                        <div class="col-md-4">
                            <div class="service-box custom_hover">
                                <div class="service-img">
                                    <img data-aos="zoom-in" src={Digital_img} alt="not found" />
                                </div>
                                <div class="service-content">
                                    <div class="service-title">
                                        <h4 data-aos="fade-up-right"><a >{digital.title}</a></h4>
                                    </div>
                                    <p className={'hegiht_newcapabilities'} data-aos="fade-up-right">{digital.description}</p>
                                        <Link to="/our-service/#SEO"  target="_top"  class="box_btn">read more</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/><br/>
             </div> 
    </section>   
            </div>
            )
        }
    }