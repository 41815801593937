import React from 'react'
import './our_service_web_design.css'
// import AOS from 'aos'
import Desktop_Design_Img from '../img_new/our_service_inner/web_design/allOurServices.png'
import tab_Design_Img from '../img_new/our_service_inner/web_design/tab_inner1.png'
import mobile_Design_Img from '../img_new/our_service_inner/web_design/mob_inner1.png'
import { BrowserRouter as Router, Switch, Route,Link } from 'react-router-dom';
export default class Our_service_web_design extends React.Component{
      componentDidMount=() =>{
       
        // AOS.init({
        //     duration: 2200,
        //   })
          
}     
  render(){
    
    return(
            <div id={ 'webdesign'}>
    {/* <div className="container total_div_top_web_design_inner" >
        <div className="row">
            <div className="col-lg-12">
                <h1 className="our_head_inner_web_design" data-aos="zoom-in">Web Design & development</h1>
            </div>
        </div>
    </div> */}
    <div className={'marg_all_heading_about our_serfirst_p'}>
                    <h2 className="about_ser_new_tags">Custom Software Development</h2>
                            <span className="about_bor_bot_serv"></span>
                            </div>
    <div className="container-fluid">
        <div className="row">
            <div className="col-lg-12">
                 <div className="col-lg-6 ">
                    <div>
                        <img src={Desktop_Design_Img} alt='' className="img_left_webdesign_inner desktop_web_design_inner  " data-aos="fade-right" /></div>
                </div>
               {/* <div className="col-lg-2 img_mrg_top_inner_web_design_1">
                    <div>
                        <img src={tab_Design_Img} alt='' className="img_left_webdesign_inner tab_web_design_inner " data-aos="fade-right" />
                    </div>
                </div>
                <div className="col-lg-2 img_mrg_top_inner_web_design_1">
                    <div>
                        <img src={mobile_Design_Img} alt='' className="img_left_webdesign_inner mob_web_design_inner " data-aos="fade-right" />
                    </div>

                </div> */}
                <div className="col-lg-6">
                    <p className="web_design_ptag_inner1" data-aos="fade-right" data-aos-offset="100" data-aos-easing="ease-in-sine">We deliver custom software solutions tailored to meet your unique business needs. Our expert developers ensure scalability, flexibility, and efficiency in every solution. With our custom development services, streamline your processes and boost productivity. We focus on user-centric designs that enhance your operational flow. Achieve optimal results with software built specifically for your business. Experience a solution that evolves with your growing requirements.</p>
                    {/* <p className="web_design_ptag_inner1" data-aos="fade-right" data-aos-offset="100" data-aos-easing="ease-in-sine">iCore Software Systems is one of the best web development companies with base in India that understands the trend of requirements and stays ahead of it with our extremely spectacular web designs and themes. Our expert web designers design the websites and themes by following proper website planning protocols and they use the following technologies to build websites</p> */}

                    {/* <div className="box_shad_new_1">
                        <p className="card_extra_ptag_inner01 fnt_txt_align_box_sha_txt" data-aos="flip-left">HTML5</p>

                        <p className="card_extra_ptag_inner01 fnt_txt_align_box_sha_txt" data-aos="flip-left">CSS3</p>

                        <p className="card_extra_ptag_inner01 fnt_txt_align_box_sha_txt" data-aos="flip-left">jQuery</p>

                        <p className="card_extra_ptag_inner01 fnt_txt_align_box_sha_txt" data-aos="flip-left"><strong>Angular JS</strong></p>
                        <p className="card_extra_ptag_inner01 fnt_txt_align_box_sha_txt" data-aos="flip-left"><strong>React JS</strong></p>
                    </div>
                    <p className="web_design_ptag_inner1" data-aos="fade-right" data-aos-offset="100" data-aos-easing="ease-in-sine">Our web designers and developers are well experienced with the ins and outs of CMSs which includes but not limited to</p>
                    <div className="box_shad_new_1">
                        <p className="card_extra_ptag_inner2 fnt_txt_align_box_sha_txt" data-aos="flip-left">WordPress</p>

                        <p className="card_extra_ptag_inner2 fnt_txt_align_box_sha_txt" data-aos="flip-left">Drupal</p>

                        <p className="card_extra_ptag_inner2 fnt_txt_align_box_sha_txt" data-aos="flip-left">Joomla</p>

                        <p className="card_extra_ptag_inner2 fnt_txt_align_box_sha_txt" data-aos="flip-left">Radiant</p>
                        <p className="card_extra_ptag_inner2 fnt_txt_align_box_sha_txt" data-aos="flip-left">Textpattern</p>
                    </div>
                    <Link to="web-application/#custom-web" target="_top" className="our_service12read">Read More...</Link> */}

                </div>
                {/*
                <div className="col-lg-2"></div> */}
            </div>
        </div>
    </div>

    {/* extra */}

</div>
  
    )
  }
}