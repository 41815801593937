import React from 'react'
import './contact_us_inner.css'
// import Header from '../header.js'
import { Redirect } from 'react-router-dom';
import Contact_us_Inner from './contact_us_inner.js'
export default class Contact_us_Inner_new extends React.Component{
        render(){
    //       const { redirect } = this.state;
    // if (redirect) {
    //   return <Redirect to="/thankyou" />;
    // }

          return (
            <div>        	
           {/* <Header/> */}
<div className="container-fluid pad_conatiner_contact_inner">
<div className="row">
            <div className="col-lg-12 bg_our_contactus_inner1">
                
            </div>

        </div>
        <div className="row form_align_cen_contact_inner ">
        {/* form */}
            <Contact_us_Inner/>
        
        </div>
    
           
    <div className="col-lg-12 pad_conatiner_contact_inner pad_conatiner_contact_inner1">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.940592790083!2d80.14793221413586!3d13.03945341691543!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526682f81b72e1%3A0xba2664b434641ee4!2siCore+Software+Systems+Pvt+Ltd!5e0!3m2!1sen!2sin!4v1550762976320" width="100%" height="450" frameborder="0" allowfullscreen title="map"></iframe>
        <div className="card_map_contact_inner">
            <div className="">
                <h4 className="find_map_txt_cont_inner">Find Us</h4>
                <h5 className="head_map_inner1">India</h5>
                                  <div className="ptag_fs_cont_inner_map">
                                      <p>iCore Software Systems Private Limited</p>
                                      <p>5th floor, Gamma Block,</p>
                                      <p> SSPDL Alpha city, OMR,</p>
                                      <p> Navalur, Chennai-600130 </p>
                                  </div>
                <br/>
                <h5 className="head_map_inner1">USA</h5>
                <p className="ptag_fs_cont_inner_map">iCore Technologies, LLC
                    <br/> 12987 Allenwood Ave
                    <br/> Frisco, TX 75035
                    <br/>
                </p>
                <br/>
            </div>
        </div>
    </div>
    {/* <div className="col-lg-1"></div>
    <div className="col-lg-10">
        <div className="col-lg-6">
            <div className="card_address_contact_inner">
                <h5 className="head_map_inner2_bot">India</h5>

                <p className="ptag_fs_cont_inner_map_card"><b>Mobile :</b> <a className="atag_under_cont_inner" href="tel:+91 73580 56001"> +91 73580 56001</a></p>
                <p className="ptag_fs_cont_inner_map_card"><b>Phone :</b> <a className="atag_under_cont_inner    " href="tel:+91 44 42059777"> +91 44 42059777</a></p>
                <p className="ptag_fs_cont_inner_map_card"><b>Website :</b> <a className="atag_under_cont_inner" href="https://www.icoresoftwaresystems.com">https://www.icoresoftwaresystems.com</a>
                    <br/>
                </p>
                <p className="ptag_fs_cont_inner_map_card"><b>Email :</b> <a className="atag_under_cont_inner" href="mailto:contact@icoresoftwaresystems.com"> contact@icoresoftwaresystems.com</a>
                    <br/>
                </p>
                <br/>
            </div>
        </div>
        
        <div className="col-lg-6">
            <div className="card_address_contact_inner">
                <h5 className="head_map_inner2_bot">USA</h5>
                <p className="ptag_fs_cont_inner_map_card"><b>Phone : </b><a className="atag_under_cont_inner" href="tel:(727) 453-9732"> (727) 453-9732</a>
                    <br/>

                </p>
                <p className="ptag_fs_cont_inner_map_card"><b>Website :</b> <a className="atag_under_cont_inner" href="https://www.icoretek.com"> https://www.icoretek.com</a>
                    <br/>
                </p>
                <p className="ptag_fs_cont_inner_map_card"><b>Email :</b> <a className="atag_under_cont_inner" href="mailto:contact@icoretek.com"> contact@icoretek.com</a>
                    <br/>
                </p>

                <br/>
            </div>
        </div>
      
    </div>
    <div className="col-lg-1"></div> */}
</div>
</div>
          )
        }
      }
      
    
      
