import React  from 'react'
import './our_service.css';
export default class Header extends React.Component{
    componentDidMount=() =>{
        // var i = null;

        // function ctmove() {
        //     i--;
        //     document.getElementById("our_services").style.backgroundPosition = i + "px";
            
        // }
        // window.setInterval(ctmove, 30);
    }     
    render(){
        return(
            <div>
                 <main>
        <section id="our_services" class="main-title">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-12 text-center">
                        <h1>Our Services</h1>
                        <p><br/></p>
                    </div>
                </div>
            </div>
        </section>
    </main>
            </div>
            )
        }
    }