import React from 'react'
import './about_us_inner.css'
import Header from '../header.js'
import About_Us_Header_Video from './about_us_inner.js'

import $ from 'jquery'

import About_us_inner_philosophy from './about_us_inner_philosophy.js'
import Rotation_work from './rotation_work.js'
import Footer from '../footer.js'
// import Ourclient_inner from './our_client_inner.js'
import About_inner_head_video from './about_inner_head_video1.js'
import About_mision from '../img_new/about_us_inner/about_inner_misson.png'
import About_vision from '../img_new/about_us_inner/about_inner_vision.png'
import About_value from '../img_new/about_us_inner/about_inner_value.png'
// import About_Why_We_bg from '../img_new/about_us_inner/about_why_we_inner.jpg'
export default class About_Us_Inner extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
     titles: {},
     description: {}
    };
  }

      componentDidMount=() =>{
  
   
        $(document).ready(function() {

          $('.show_text_inner_hideshow').hover(function() {
              var id = $(this).attr('data-id');
              $('.b-' + id + '-text').show();
          }, function() {
              $('.b-text').hide();
          }).on('click', function() {
              var id = $(this).attr('data-id');
              $('.b-' + id + '-text').toggleClass('selected');
          });

          // for title hide
          $(".show_text_inner_hideshow").hover(function() {
                  $("#container_sh #box").css("visibility", "hidden");
              },
              function() {
                  $("#container_sh #box").css("visibility", "visible");
              });
      });
        // animation after 10sec
        $(document).ready(function(){
          setInterval(function(){ 
            $('#outerspace').addClass("rocket")
          }, 1500);
        });     
       
       
      // 2nd rotation
      $(document).ready(function(){
        setInterval(function(){ 
          $('#outerspace2').addClass("rocket2")
        }, 11000);
      });     
    
       // 3rd rotation
       $(document).ready(function(){
        setInterval(function(){ 
          $('#outerspace3').addClass("rocket3")
        }, 21000);
      
      }); 
      const titles = {
        title: "Empowering Businesses with Next-Gen Technology",
        strategic: "Your Trusted Tech Partner",
      };
      const description = {
        itSolutiondescription: "Driving business growth through custom software, web and mobile applications, and dynamic e-commerce solutions with secure payment systems and real-time analytics. Harnessing advanced technologies like AI, VR, and AR, we create immersive experiences and smart systems that enhance operations and customer engagement. From CRM systems and cloud integration to DevOps, our comprehensive services ensure seamless, scalable solutions. Based in Chennai, we deliver innovative technology to help businesses thrive in a competitive digital landscape.",
        visionDescription: "To be a trusted global partner in providing technology solutions that drive business success and empower organisations to excel in a digital-first world.",
        missionDescription: "To achieve our vision by delivering high- quality, customised technology solutions, fostering strong client relationships, leveraging cutting-edge technologies, and maintaining a commitment to excellence, collaboration, and continuous improvement in every project we undertake.",
        valuesDescrition:" Tech-powered innovation, problem-solving mastery, exceptional outcomes, customer-focused dedication.",
      }
      this.setState({ titles, description });
}    
   
  render(){
     const { titles, description } = this.state
    return(
        <div>
  <Header/>
    <div className="container-fluid">
    {/* <About_inner_head_video/> */}
        <div className="row">
            <div className="col-lg-12 solution-head-content">
                <div className="about_redes_head">
                <div className={'marg_all_heading_about'}>
                <h2 className="about_ser_new_tags">{titles.title}</h2>
                        <span className="about_bor_bot_serv"></span>
                        </div>
                    <p className="about_inner_ptag" data-aos="fade-right">{description.itSolutiondescription}</p>
            
                </div>
            </div>

        </div>

   

{/* heading */}

                    <div className={'marg_all_heading_about'}>
                <h2 className="about_ser_new_tags">{titles.strategic}</h2>
                        <span className="about_bor_bot_serv"></span>
                        </div>
                        {/* <h3 className=" txt_bor_align_about_inner1">Our Capabilities</h3>
                        <p className=" ">Our line of services and our way of harnessing the potential of technology advancement enables us to provide a wide array of web application development services and eCommerce development solutions. Along with that we also provide customized Mobile Applications development services and US IT staffing solutions</p> */}


        <div className="only_web_view_inner_rotate">
        <div className="row">
                <div className="col-lg-12 bg_rotate_img_inner_10 ">
                  <div className="row">
                  <div className="col-lg-1"></div>
                    <div className="col-lg-5" >
                        <div className="col-lg-5">
                        <div className="zoom-effect-hvr_img_inner">
                        <div id="outerspace" >
                            <img src={About_vision} alt="not found" className="show_text_inner_hideshow new_img_rotate" data-id="1"/> 
                          </div>
                        </div></div>
                        <div className="col-lg-5 mgr_left_minus13_inner">
                        <div className="zoom-effect-hvr_img_inner">
                        <div id="outerspace2">
                            <img src={About_mision} alt="not found" className="show_text_inner_hideshow new_img_rotate" data-id="2"/>
                        </div></div></div>
                        <div className="col-lg-5 img_align_inner_mission">
                        <div className="zoom-effect-hvr_img_inner">
                        <div id="outerspace3">
                            <img src={About_value} alt="not found" className="show_text_inner_hideshow new_img_rotate" data-id="3"/>
                        </div></div></div>

                    </div>
                    
                    <div className="col-lg-5 pad_new_our_vis">
                        <div className={'our_mis_vis_about'}>
                        <h3 className=" txt_bor_align_about_inner1">Our Vision</h3>
                        <p className="min_vis_jsuti">{description.visionDescription}</p>
                        </div>
                        <div className={'our_mis_vis_about'}>
                        <h3 className=" txt_bor_align_about_inner1">Our Mission</h3>
                        <p className="min_vis_jsuti">{description.missionDescription}</p>
                        </div>
                        <div className={'our_mis_vis_about'}>
                        <h3 className=" txt_bor_align_about_inner1">Our Values</h3>
                        <p className="min_vis_jsuti">{description.valuesDescrition}</p>
                    </div>
                    </div>
                    <div className="col-lg-1"></div>
                   
                </div>
                </div>
            </div>
            </div>
         
            <div className="only_mobile_view_inner_rotate">
        <div className="row solution-strategic-Partner-alignment">
                <div className="col-lg-12 bg_rotate_img_inner_10">
                 
                    <div className="col-lg-12">
                    <div className="zoom-effect-hvr_img_inner">
                        <div id="outerspace" >
                            <img src={About_vision} alt="not found" className="show_text_inner_hideshow tab_img_size_for_round_img new_img_rotate" data-id="1"/> 
                          </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                    <div className="" >
                    <p className="min_vis_jsuti">The fundamental vision of iCore Software is to hack the technological problems of our clients by building effective solutions / products to improve efficiency, reduce costs and create immense value for our clients</p>
                            </div>
                    </div>
                </div>
                {/* second row */}
               
                <div className="col-lg-12 bg_rotate_img_inner_10">
                 
                 <div className="col-lg-12">
                 <div className="zoom-effect-hvr_img_inner">
                     <div id="outerspace" >
                         <img src={About_mision} alt="not found" className="show_text_inner_hideshow tab_img_size_for_round_img new_img_rotate" data-id="1"/> 
                       </div>
                     </div>
                 </div>
                 <div className="col-lg-12">
                 <div className="" >
                             <p className="min_vis_jsuti">To create or build reliable and effective products / solutions on deep analysis of client requirements and alleviating customer pain points to achieve maximum value proposition</p>
                         </div>
                 </div>
             </div>
             {/* third row */}
             <div className="col-lg-12 bg_rotate_img_inner_10">
                 
                 <div className="col-lg-12">
                 <div className="zoom-effect-hvr_img_inner">
                     <div id="outerspace" >
                         <img src={About_value} alt="not found" className="show_text_inner_hideshow tab_img_size_for_round_img new_img_rotate" data-id="1"/> 
                       </div>
                     </div>
                 </div>
                 <div className="col-lg-12">
                 <div className="" >
                             <p className="min_vis_jsuti">Technology-driven, Problem-solving, Excellence, Customer-focus, Accountability</p>
                         </div>
                 </div>
             </div>
        </div>
        </div>
             
    </div>
</div>
  
    )
  }
}