import React from 'react'
import './our_service_ecommerce.css'

import {$} from 'jquery'
import Main_Ecommerce from '../img_new/our_service_inner/augment_reality.png'
import { BrowserRouter as Router, Switch, Route,Link } from 'react-router-dom';
// import AOS from 'aos'
export default class Augmented_inner extends React.Component{
      componentDidMount=() =>{
        // AOS.init();
    

}      
  render(){
    
    return(
            <div>
       <div className={'marg_all_heading_about our_serfirst_p'} id={'AR'}>
                    <h2 className="about_ser_new_tags">Virtual Reality (VR) & Augmented Reality (AR)</h2>
                            <span className="about_bor_bot_serv"></span>
                            </div>
                    {/* <div className="container total_div_top_augment" id={'AR'} >
    <div className="row">
        <div className="col-lg-12">
            <h3 className="our_ecommerce_head_inner_service">Augmented Reality / Virtual Reality Services</h3>
        </div>
    </div>
    </div> */}
  
  <div className="container-fluid">
  <div className="row">
    <div className="col-lg-12">
      <div className="row d-flex align-items-center">
        <div className="col-lg-6">
          <p className="ecomerce_ptag_inner1">
            Deliver immersive user experiences with our VR and AR solutions. Engage your audience through interactive, lifelike digital environments. Transform the way customers interact with your brand using cutting-edge AR/VR technology. We create tailored solutions for training, marketing, and entertainment. Elevate your business by blending the physical and digital worlds seamlessly. Drive innovation and engagement with our custom AR and VR solutions.
          </p>
        </div>
        <div className="col-lg-6">
          <img src={Main_Ecommerce} style={{width:"50%" ,marginLeft:"20%"}} alt="not found" className="img-fluid" />
        </div>
      </div>
            {/* <p className="ecomerce_ptag_inner1" >Constant evolutionary changes in the information era has enabled us to embrace technology in different ways. And we mean this literally with the entry of AR / VR technology taking hold of the technology landscape. Every technology of the past is being rechristened 
            by incorporation of AR and VR into them</p> */}
            </div>
            {/* <div className="col-lg-6">
              
           
            <p className="ecomerce_ptag_inner1" >We are now in the right phase of the information era where the AR/VR technologies in science fiction movies of the 80s come to reality. Augmented Reality and Virtual Reality is all about enhancing the digital experience of using technology and make it as real as possible. The accelerated pace of its development will soon change 
            the way the world embraces technology.</p>
            <p className="ecomerce_ptag_inner1" >The emergence of AR/VR has given rise to endless possibilities of redefining the ways a customer can use technology. Businesses across industries have begun their initial steps in enhancing their services with the incorporation of AR / VR into their products and services </p>
            <p className="ecomerce_ptag_inner1" >iCore has foreseen this revolution and understood the possibilities 
            that this phase of this era has to offer. Hence iCore has embraced AR / VR technologies and incorporated them 
            into our service delivery to meet the needs of our clients</p>

            <p className="ecomerce_ptag_inner1" > One of our successful applications of the AR / VR technologies is the remote incident management tool that we built for Trintas. This is an Augmented Reality application that solves critical printer issues. This AR application enables the printer-service technician to solve issues without 
            having to move the printer to the service facility.</p>
            <p className="ecomerce_ptag_inner1 " >This application has enabled Trintas to save considerable amount of time and operational costs. Also this application has transformed Trintas’ operational 
            excellence and has taken it to the next level. <Link to="/our-work/#AR_ourwork" target="_top" style={{color:'blue'}}> Read more about it here</Link></p>
            <p className="ecomerce_ptag_inner1" >Our future plans involve expanding AR / VR products and solutions into the Retail, Entertainment, Education, Publishing,
                 Health Sciences and Manufacturing domains</p>
                 <p className="ecomerce_ptag_inner1" >Our other efforts of AR / VR technology involves our Solution Architects envisioning the incorporation of AR / VR elements into eCommerce, 
                Mobile Applications and Web Applications to build efficient products/solutions</p>
                <Link to="/our-niche/" target="_top" className="our_service12read">Read More...</Link>
            </div> */}
            </div>
            </div>
            {/* <div class="stage">
  <div class="ball"></div>
</div> */}
            </div>
  
    )
  }
}