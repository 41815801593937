
import React  from 'react'
import './about_us_inner_video.css'
// import Header_Video_new from '../img_new/videos/header_video4.mp4'
import About_bg_img_new from '../img_new/about_us_inner/about_bg_img_new.png'
export default class About_inner_head_video extends React.Component{
    componentDidMount(){
      
            }


    render(){
        return(    
            <div><br/><br/>
                <div>         
                    </div>
             <div className='bg_color'>
                
 {/* <header class="header_about_inner valign bg-img overlay_hide_about " data-scroll-index="0" data-overlay-dark="5"  data-stellar-background-ratio="0.5">
 <img className="bg-vid video_bg_header" src={About_bg_img_new} alt="not-found"/>

        <div class="container">
      
       
            <div class="row">
                <div class="full-width text-center caption ">
                   
                </div>
            </div>
        </div>

    </header>  */}
       
           
            </div></div>
           
            
        )
    }
}