import React from 'react'
import './our_work_page/our_work_inner.css'
// import Loading_contact from './loader_new_contact.js'
import Contact_us_Inner_new from './contact_us_page/contact_us_new.js'
// import Contact_us_Inner_new1 from './contact_us_page/contact_us_map.js'
import Contact_us_Inner from './contact_us_page/contact_us_inner.js'
import Header from './header.js'
import Footer from './footer.js'
import $ from 'jquery'
import DocumentMeta from 'react-document-meta';
export default class Our_work_Inner_Connect extends React.Component{
      componentDidMount=() =>{
      window.scrollTo(0, 0)
        // $(document).keydown(function (event) {
        //     if (event.keyCode == 123) { // Prevent F12
        //         return false;
        //     } else if (event.ctrlKey && event.shiftKey && event.keyCode == 73) { // Prevent Ctrl+Shift+I        
        //         return false;
        //     }
        // });
        // $(document).on("contextmenu", function (e) {        
        //     e.preventDefault();
        // });
        $(document).ready(function() {
          $('.nav_bar_adding_contact').addClass('active_cus');
      });
}      
    render() {
        // Get the current URL path
        const currentPath = window.location.pathname;
        const meta = {
            title: 'Contact-us for your IT Requirement | contact-us',
            description: 'iCore Software Systems is a technology-driven web design and development company that has extended its capabilities to web application development, building customized eCommerce solutions and mobile applications',
            canonical: `https://www.icoresoftwaresystems.com${currentPath}`,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: 'Technology-driven, Problem-solving, Excellence, Customer-focus, Accountability'
                }
            }
        };
    
    return(
            <div>
       <DocumentMeta {...meta}/>
<Header/>
 <Contact_us_Inner_new/>
 {/* <Contact_us_Inner/> */}
 <Footer/> 

            </div>
  
    )
  }
}