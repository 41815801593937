// import React from 'react';
// import ReactDOM from 'react-dom';
// import Coverflow from 'react-coverflow';
// import './swiper.css'
// import Slider1 from './img/slider/1.png'
// import Slider6 from './img/slider/6.png'
// import Slider2 from './img/slider/2.png'
// import Slider3 from './img/slider/3.png'
// import Slider4 from './img/slider/4.png'
// import Slider5 from './img/slider/5.png'
// import mobile_img_swiper from './img_new/mobile-3640069_960_720.png'


// export default class Swiper extends React.Component{
//     constructor(props) {
//         super(props);
    
//         this.state = {
//           active: 0
//         };
//         this.state  = {
//           width: '400px',
//           height:'400px'
//         };
//       }
//      componentDidMount(){
//         this.inter=setInterval(()=> this.interval(),2000);

//         //   window.setInterval(()=>{
//         //       this.state.active=this.state.active+1;
//         //       this.state.active=this.state.active<=10?this.state.active:this.state.active=0;
//         //   },1000)
//       };
//       interval(){
    
//         var val=this.state.active+1;
//         val=val<=5?val:val=0;
//         this.setState({
//             active:val
//         })
//     }
//     render(){
      
//         // var swiper = new Swiper('.swiper-container', {
//         //     pagination: '.swiper-pagination',
//         //     effect: 'coverflow',
//         //     grabCursor: true,
//         //     centeredSlides: true,
//         //     slidesPerView: 'auto',
//         //     coverflow: {
//         //       rotate: 50,
//         //       stretch: 0,
//         //       depth: 100,
//         //       modifier: 1,
//         //       slideShadows : true
//         //     },
//         //     loop: true,
//         //     autoplay:{
//         //       delay:5000,
//         //       disableOnInteraction:false,
//         //     }
            
            
//         //   });
//         return(
//             <div>
//                <div className={'marg_all_heading'}>
//                 <h2 className="about_ser_new_tags">Our mobile applications</h2>
//                         <span className="about_bor_bot_serv"></span>
//                         </div><br/>
//        {/* <div className={'fix_mobileframe'}><img className={'fix_mobileswiperimg'} src={mobile_img_swiper}/></div> */}
//         <Coverflow
//           width={'20%'}
//           height={'520'}
//           displayQuantityOfSide={2}
//           navigation={true}
//           enableHeading={false}
//           enableScroll={false}
//           active={0}
//           infiniteScroll={false} 
//           loop= {false} 
//         >
//          <div className={''}>
//            <img style={{height:'auto',width:'100%',boxShadow:'0px 0px 0px 0px!important'}} className="pic_swiper" src="http://localhost:3000/static/media/sample.6c6ad0ea.png" alt='Album One' />
//          </div>
//          <div className={''}>
//            <h1>shan</h1>
//          </div>
//          <div className={''}>
//            <h1>shan</h1>
//          </div>
//         {/* <img style={{height:'auto',width:'100%',boxShadow:'0px 0px 0px 0px!important'}} className="pic_swiper" src="http://localhost:3000/static/media/sample.6c6ad0ea.png" alt='Album One' /> */}
//         {/* <img style={{height:'300px',width:'61%',boxShadow:'0px 0px 0px 0px!important'}} className="pic_swiper" src={Slider3} alt='Album three' /> */}
//         {/* <img style={{height:'300px',width:'61%',boxShadow:'0px 0px 0px 0px!important'}} className="pic_swiper" src={Slider5} alt='Album five' /> */}
//         {/* <img style={{height:'300px',width:'61%',boxShadow:'0px 0px 0px 0px!important'}} className="pic_swiper" src={Slider4} alt='Album four' /> */}
//             {/* <img style={{height:'300px',width:'61%',boxShadow:'0px 0px 0px 0px!important'}} className="pic_swiper" src={Slider1} alt='Album six' /> */}
        
//           {/* <img style={{height:'300px',width:'61%',boxShadow:'0px 0px 0px 0px!important'}} className="pic_swiper" src={Slider2} alt='Album seven' /> */}
          
         
          
         
//         </Coverflow>
//         <br/>
//       </div>
//     );
//   }
// }
 
import React from 'react'
import $ from 'jquery'
import 'slick-carousel';
import './our_client.css'
import './swiper.css'
// import irdie_driver from './img_new/ourclients/driver_logo_sli.png'
import driver_new from './img_new/ourclients/driver1.png'
// import Passenger_new from './img_new/our_work_inner/new/iride_pass_new.png'
import Catalina_new from './img_new/our_work_inner/new/catlina_new1.png'
import Google_paly from './img_new/our_work_inner/new/google_play1.png'
import Apple_paly from './img_new/our_work_inner/new/apple_play1.png'
export default class Ourclient extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      iRideApp: {},
      catalinaApp: {}
    }
  }
    
    google_playbutn() {
        window.location.assign('https://play.google.com/store/apps/details?id=com.icore.apps.iride.driver');
      }
      apple_playbutn() {
        window.location.assign('https://itunes.apple.com/us/app/iride-driver/id1326527505?mt=8');
      }
      apple_playbutn_catalina() {
        window.location.assign('https://apps.apple.com/us/app/catalina-arf/id1217479015');
      }
      google_playbutn_catalina() {
        window.location.assign('https://play.google.com/store/apps/details?id=com.trintas.catalinaarf');
      }
    componentDidMount(){
        // this.routeChange = this.routeChange.bind(this);
      $(document).ready(function(){
        $('.customer-logosss').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 1500,
            arrows: true,
            nextArrow: '<i class="fa fa-angle-left left_arrow_slick_swiper" aria-hidden="true"></i>',
             prevArrow:'<i class="fa fa-angle-right right_arrrow_slick_swiper" aria-hidden="true"></i>',
             
            dots: false,
          
            pauseOnHover: true,
           
        });
    });

    const iRideApp ={
      driverDescription: "The iRide Driver Mobile App is a comprehensive solution designed to empower cab drivers with essential tools for efficient operations. It enables drivers to find and accept ride requests, manage vehicle registrations, set fare rates, navigate routes seamlessly, and track rides in real time. This app simplifies daily tasks and enhances productivity, ensuring a smooth experience for drivers.",
      passengerDescription: "The iRide Passenger Mobile App is a user-friendly platform created to provide passengers with a seamless travel experience. It allows users to locate and book taxis effortlessly, manage their trips efficiently, and customize preferences to suit their needs. With its intuitive design, the app ensures convenience and reliability, making every journey hassle-free."
    }
    const catalinaApp = {
      catalinDescription: "Our Catalina ARF (Asset Recovery Form) is an advanced Augmented Reality application designed to revolutionize remote issue resolution for printers and PCs. This cutting-edge app empowers technicians with on-screen, real-time visual solutions while enabling seamless collaboration, enhancing efficiency and precision in troubleshooting tasks."
    }

  this.setState({iRideApp, catalinaApp})
    }
    render(){
      const { iRideApp, catalinaApp } = this.state
        return(
        <div>
            <div class="container-fluid">
            <div className={'marg_all_heading'}>
            <h2 className="about_ser_new_tags">Top mobile applications</h2>
                        <span className="about_bor_bot_serv"></span>
                        </div>
              </div>
                <section class="customer-logosss slider">
                
                <div className={'row'}>
                    <div className={'col-lg-12'}>
                      <div>
                        <div className={'for_our_worknew'}>
                        <h3 className="txt_bor_align_about_inner1 slider_head_fnt text-center" style={{left:'10%',fontSize:'30px'}}>Taxi app</h3>
                    <br/><div className={'col-lg-5'}>
                   
                      <img alt="not found" className={'img_ourwork_new_logo_swiper'}  src={driver_new} />
                    </div>
                    <div className={'col-lg-6'}>
                    <h3 className=" txt_bor_align_about_inner1">iRide </h3>
                       
                        <p className="min_vis_jsuti">{iRideApp.driverDescription}</p>
                       <p className="min_vis_jsuti">{iRideApp.passengerDescription}</p>
                       <br/>

                         <h3 className=" txt_bor_align_about_inner1">Get App</h3>
                         <div className="total_btn">
                        
                        <img src={Google_paly} onClick={this.google_playbutn.bind(this)} alt="not" className={'goole_play_cssbutn'}/>
                       
                        
                        <img src={Apple_paly} alt="not" onClick={this.apple_playbutn.bind(this)} className={'goole_play_cssbutn'}/>
                
               
                    
                         {/* <a href="https://itunes.apple.com/us/app/iride-driver/id1326527505?mt=8">
                     <div class="group_btn_ibra" >
                    <i class="fa group_btn_ibra_icon fa-apple fa-3x pull-left" aria-hidden="true"></i>
                    
                    <span class="btn-caption">App Store</span>
                   
                </div></a> */}
               
                {/* <div class="group_btn_ibra padd_rite">
                <a href="https://play.google.com/store/apps/details?id=com.icore.apps.iride.driver">
                    <i class="fab group_btn_ibra_icon fa-google-play fa-3x pull-left" aria-hidden="true"></i>
                   
                    <span class="btn-caption"><img className={'img_google_ply_btn'} src="https://cdn4.iconfinder.com/data/icons/free-colorful-icons/360/google_play.png" />GET IN ON <br/><span> GOOGLE PLAY</span></span>
                    </a> </div> */}
                
               
                         {/* <img src={Google_paly} onClick={this.google_playbutn.bind(this)} alt="not" className={'goole_play_cssbutn'}/> */}
                        
                         
                         {/* <img src={Apple_paly} alt="not" onClick={this.apple_playbutn.bind(this)} className={'goole_play_cssbutn'}/> */}
                        
                     {/*    <a href="https://itunes.apple.com/us/app/iride-driver/id1326527505?mt=8">
                     <div class="group_btn_ibra" >
                 
                
                    <i class="fa group_btn_ibra_icon fa-apple fa-3x pull-left" aria-hidden="true"></i>
                    <span class="btn-label" style={{fontSize:'12px'}}>Download on the </span>
                    <br/>
                    <span class="btn-caption">App Store</span>
                   
                </div></a>
               
                <div class="group_btn_ibra padd_rite">
                <a href="https://play.google.com/store/apps/details?id=com.icore.apps.iride.driver">
                    <i class="fa group_btn_ibra_icon fa-android fa-3x pull-left" aria-hidden="true"></i>
                    <span class="btn-label">Download on the</span>
                    <br/>
                    <span class="btn-caption">Google Play</span>
                    </a> </div>
                
                </div>                      
                <span class="fa fa-apple fa-3x pull-left"></span> 
                <span class="btn-label">Download on the</span>
                <span class="btn-caption">App Store</span>
            </a> */}
                    </div>
                    </div>
                    </div>
                </div></div></div>
                    {/* second slider */}
                     {/* <div className={'row'}>
                    <div className={'col-lg-12'}>
                        <div className={'for_our_worknew'}>
                        <h3 className="txt_bor_align_about_inner1 text-center" style={{left:'10%',fontSize:'30px'}}>Taxi app</h3>
                    <div className={'col-lg-5'}>
                   
                      <img className={'img_ourwork_new_logo_swiper'} src={Passenger_new} />
                    </div>
                    <div className={'col-lg-6'}>
                    <h3 className=" txt_bor_align_about_inner1">iRide </h3>
                       
                        <p className="min_vis_jsuti">iCore has developed this Passenger module of the taxi app to provide a convenient wayto travel around the places by just booking the app through their mobile apps.iRide Passenger is the online taxi riding service. This app is used at the passenger end of the iRide taxi service. Passengers looking for taxis in the vicinity can find taxis through this app. iRidePassenger has features of advanced booking of taxis for a future date, emergency options, trip management, driver and vehicle preferences etc</p>
                        <br/>
                        <h3 className=" txt_bor_align_about_inner1">Get App</h3>
                     
                     <div className="total_btn">
                        
                        <img src={Google_paly} onClick={this.google_playbutn.bind(this)} alt="not" className={'goole_play_cssbutn'}/>
                       
                        
                        <img src={Apple_paly} alt="not" onClick={this.apple_playbutn.bind(this)} className={'goole_play_cssbutn'}/>
                
                </div>                     
                    </div>
                    </div>
                    </div>
                </div>  */}
                {/* 3rd slider */}
                <div className={'row'}>
                    <div className={'col-lg-12'}>
                        <div className={'for_our_worknew'}>
                        <h3 className="txt_bor_align_about_inner1 text-center slider_head_fnt" style={{left:'10%',fontSize:'30px',padding:'1% 0'}}>Augmented reality app</h3>
                        <div className={'col-lg-5'}>
                   
                      <img alt="not found" className={'img_ourwork_new_logo_swiper'} src={Catalina_new} />
                    </div>
                    <div className={'col-lg-6'}>
                    <h3 className=" txt_bor_align_about_inner1">Catalina ARF</h3>
                        
                        <p className="min_vis_jsuti">{catalinaApp.catalinDescription}</p>
                        <br/>
                        <h3 className=" txt_bor_align_about_inner1">Get App</h3>
                      {/* <div className="total_btn">
                         <a href="https://itunes.apple.com/us/app/catalina-arf/id1217479015?mt=8">
                     <div class="group_btn_ibra" >
                    <i class="fa group_btn_ibra_icon fa-apple fa-3x pull-left" aria-hidden="true"></i>
                    
                    <span class="btn-caption">App Store</span>
                   
                </div></a>
               
                <div class="group_btn_ibra padd_rite">
                <a href=" https://play.google.com/store/apps/details?id=com.trintas.catalinaarf">
                    <i class="fab group_btn_ibra_icon fa-google-play fa-3x pull-left" aria-hidden="true"></i>
                    
                    <span class="btn-caption">Google Play</span>
                    </a> </div>
                
                </div>                          */}
                  <div className="total_btn">
                        
                        <img src={Google_paly} onClick={this.google_playbutn_catalina.bind(this)} alt="not" className={'goole_play_cssbutn'}/>
                       
                        
                        <img src={Apple_paly} alt="not" onClick={this.apple_playbutn_catalina.bind(this)} className={'goole_play_cssbutn'}/>
                
                </div> 
                {/* <div className="total_btn">
                        
                        <img src={Google_paly} onClick={this.google_playbutn.bind(this)} alt="not" className={'goole_play_cssbutn'}/>
                       
                        
                        <img src={Apple_paly} alt="not" onClick={this.apple_playbutn.bind(this)} className={'goole_play_cssbutn'}/>
                
                </div>         */}
                    </div>
                    </div>
                    </div>
                </div>
                </section>  
            
            </div>
       
        
        )
    }
}