import React from 'react'
import './our_work_inner.css'
import Header from '../header.js'
import $ from 'jquery'
import Iride_mob_driver from '../img_new/our_work_inner/new/iride_driver_2.png'
import Iride_mob_pass from '../img_new/our_work_inner/new/iride_pass_new.png'
import Itrack_mob from '../img_new/our_work_inner/new/itrack.png'
import Pks_mob from '../img_new/our_work_inner/new/pks_new.png'
import Catlina_new from '../img_new/our_work_inner/new/catlina_new1.png'
import Newfoil from '../img_new/our_work_inner/new/new_foil.png'
import Readycross from '../img_new/our_work_inner/new/readycross.png'
import AgileAdvantage from '../img_new/our_work_inner/new/agile_advantage.png'
import iCoretek_logo from '../img_new/our_work_inner/new/icpre_tek_new_logo1.png'
import Central_fans from '../img_new/our_work_inner/new/central_fans.png'
import Trintas_new_n from '../img_new/our_work_inner/new/trintas_new_n.png'
import iPortal from '../img_new/our_work_inner/new/iportal.png'
import Google_paly from '../img_new/our_work_inner/new/google_play1.png'
import Apple_paly from '../img_new/our_work_inner/new/apple_play1.png'
import ORM from '../img_new/our_work_inner/new/orm.png'
import today_contact from '../img_new/our_work_inner/new/tdycontact.png'
import ppfimage from '../img_new/videos/ppf_case_web.png'
import { BrowserRouter as Router, Switch, Route,Link } from 'react-router-dom';
export default class Our_work_Inner extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            ourWorkContents: {}
        }
    }
    google_playbutnwork() {
        window.location.assign('https://play.google.com/store/apps/details?id=com.icore.apps.iride.driver');
      }
      apple_playbutnwork() {
        window.location.assign('https://itunes.apple.com/us/app/iride-driver/id1326527505?mt=8');
      }
      
      apple_playworkbutn_catalina() {
        window.location.assign('https://apps.apple.com/us/app/catalina-arf/id1217479015');
      }
      google_playworkbutn_catalina() {
        window.location.assign('https://play.google.com/store/apps/details?id=com.trintas.catalinaarf');
      }
      componentDidMount() {
        const ourWorkContents = {
            iPortalDescription: "iPortal is a streamlined web app designed to simplify resume management for US IT staffing firms. It offers a centralized platform to store and easily retrieve candidate profiles, saving time during the recruitment process. With its bulk emailing feature, you can quickly communicate with multiple candidates or clients at once. The secure, user-friendly interface ensures that your data is protected while making resume management efficient and hassle-free. iPortal is the ideal solution for IT staffing firms looking to enhance productivity and improve recruitment workflows.",
            iRideDriverDescription: "The iRide Driver Mobile App is a comprehensive solution designed to empower cab drivers with essential tools for efficient operations. It enables drivers to find and accept ride requests, manage vehicle registrations, set fare rates, navigate routes seamlessly, and track rides in real time. This app simplifies daily tasks and enhances productivity, ensuring a smooth experience for drivers.",
            iRidePassengerDescription: "The iRide Passenger Mobile App is a user-friendly platform created to provide passengers with a seamless travel experience. It allows users to locate and book taxis effortlessly, manage their trips efficiently, and customize preferences to suit their needs. With its intuitive design, the app ensures convenience and reliability, making every journey hassle-free.",
            CatalinaAPDescription: "Our Catalina ARF (Asset Recovery Form) is an advanced Augmented Reality application designed to revolutionize remote issue resolution for printers and PCs. This cutting-edge app empowers technicians with on-screen, real-time visual solutions while enabling seamless collaboration, enhancing efficiency and precision in troubleshooting tasks.",
            iTrackDescription: "iTrack is a comprehensive incident management tool that helps capture, track, and resolve issues efficiently, while also serving as a robust project management system. It allows teams to streamline incident reporting, monitor progress, and ensure timely resolutions, all in one place. iTrack helps manage projects by tracking tasks, deadlines, and resources to keep everything on schedule. With real-time updates, secure data handling, and collaboration features, iTrack ensures smooth communication and seamless workflow, making it the ideal solution for businesses seeking to enhance both incident management and project efficiency.",
            trintasDescription: "Trintas is an admin module web and mobile application designed to efficiently manage computer peripheral services, connecting customers with vendors in real-time. It allows administrators to monitor service performance, track progress, and ensure smooth operations. Additionally, the module provides detailed reports, offering insights into service metrics and performance, helping businesses optimize their processes. With a user-friendly interface and robust functionalities, the Trintas Admin Module ensures effective management and enhanced service delivery.",
            pksDescription: "Persistent Knowledge Solutions, a leading recruitment and staffing company, approached iCore Software Systems to revamp their website with a more interactive design and enhanced features. After thoroughly analyzing the current website's functionalities and design during the requirement gathering phase, we identified key areas for improvement. Leveraging these insights, we successfully rebuilt the website with a fresh, interactive design and upgraded features, ensuring a seamless experience for users while keeping costs minimal, as per the client's specifications.",
            licDescription: "We have designed and developed a variety of applications and websites for iCoreTek, a company based in the US. Our contributions include the development of eCommerce websites, mobile applications, and numerous web applications tailored to iCoretek's needs. Additionally, we have supported iCoreTek with IT staffing and recruitment services for their clients, ensuring they have the right talent to drive success.",
            readygrassDescription: "Readygrass, based in the UK, specializes in providing artificial grass to customers through its e-commerce platform and is one of the largest suppliers and stockists of artificial grass in the country. iCore Software Systems addressed a critical issue with the payment gateway (Secure Trading) on their ASP.NET-based website, initially developed by the client. Leveraging our extensive expertise in Dot NET, we successfully resolved this complex problem, which had been challenging for the website stakeholders. This project showcases our proficiency in Dot NET, as well as our ability to identify and fix core issues within Dot NET websites. Our success in this project led to an increased number of inquiries from clients seeking Dot NET development services, recognizing our capability to handle such projects seamlessly.",
            ormDescription: "This project consists of two main components. The first part of the ORM plugin automatically retrieves reviews word-for-word from review sites and posts them in a designated section on the website, as decided by the company or web admin. The second part focuses on soliciting reviews, which is facilitated through a landing page. The landing page features a form on one side where users can submit their review, including fields for First Name, Last Name, and a review box. Users can also provide a star rating of up to 5 stars. After submitting the review, the next page displays a button allowing users to copy their review and provides options to redirect them to review sites, enabling them to post their review directly.",
            newfoilDescription: "The objective of this project was to make the website fully responsive, and iCore Software Systems achieved this successfully with the expertise of our in-house front-end team. Using Bootstrap technology, we ensured the website was compatible with all devices, including desktops, iPads, and mobile phones. We meticulously followed the original design styles of the desktop version while completing the tasks within the guidelines and timelines set by the client. The client provided very positive feedback at the end of the project, expressing complete satisfaction. Not a single bug or correction was raised upon delivery, demonstrating the quality and precision of our work.",
            agileDescription: "This project involved the design and development of a website for an Agile Training client based in the USA. iCore Software Systems handled the end-to-end design and development process, successfully meeting the client's requirements. Built as a CMS (Content Management System) website using the latest WordPress technology, the site includes five main pages along with several subpages. The admin can add details about the Agile Trainer on the About Us page, while the Advisory page outlines the key advisory services offered to trainees. Candidates interested in training can register and select from various Agile methodology training options on the Training page. iCore completed the project within the client's set deadline, and the client expressed full satisfaction with the performance of our development team.",
            centralFansDescription: "This project, developed by iCore Software Systems in Java, was for a client providing industrial fans to commercial customers in the UK. The goal of the project was to create a Fan Selector module that allows users to select a specific fan based on the Volume and Pressure entered in the front end. Users can also view the performance graph and data for the selected fan. Key motor data such as Motor Power, Full Load Speed, Full Load Current, Starting Current, and Starting Method are displayed, along with performance data like Required Speed, Output Velocity, and Absorbed Power. This project highlights iCore’s expertise in developing user-friendly front-end interfaces while also ensuring detailed and efficient back-end development.",
            payPerfixDescription: "Payperfix.net  is an innovative outsourcing marketplace designed to connect companies with skilled professionals for project-based hiring. The platform offers a seamless, user-friendly experience that allows businesses to find the right talent quickly and efficiently, ensuring high-quality results for every project. Whether you need expert developers, designers, or other specialized professionals, Payperfix.net  makes it easy to hire, manage, and collaborate on projects with top-tier freelancers. Simplify your hiring process, reduce costs, and achieve outstanding project outcomes with Payperfix.net ."
        }

        this.setState({ ourWorkContents });
      }
  render(){
    const { ourWorkContents } = this.state
    return( 
            <div>
   <Header/>
<div className="container-fluid">
<div className={ 'row our-work-container'}>

<div className=" bg_our_work_inner1">

</div>
</div>
   
   {/* fourth sections Catalina*/}
    <div className="row our-work-container">
    <div className={ 'marg_all_heading'}>
        <h2 className="about_ser_new_tags">iRide</h2>
        <span className="about_bor_bot_serv"></span>
    </div>
        <div className=" mrg_bot_our_work_2sec ">
            {/* <h4 className="our_work_prgt2_title" data-aos="fade-up-left">iRide Driver</h4> */}
            <div className="col-lg-6">
                <img src={Iride_mob_driver} data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" alt="" className="rgth_iride_img3" />
            </div>
            <div className="col-lg-6">
            <p className="left_iride_ptag1">{ourWorkContents.iRideDriverDescription}</p>
            <p className="left_iride_ptag1">{ourWorkContents.iRidePassengerDescription}</p>
                {/* <p className="left_iride_ptag1">As a Taxi booking app development company in Texas, iCore Software has emerged as a Uber-clone app development firm which has the following 2 modules:</p>
                <p className="left_iride_ptag1" data-aos="fade-up-left"><strong>iRide Driver</strong> is an online transportation application that allows the cab drivers to find rides in the city. This application is installed in the smartphone and has an easy to use UI that enables the cab drivers to register their vehicle and find rides. The functionalities of this application include finding nearby rides, fixing costs (with minimal variation from base costs), ride routing, ride tracking etc. iRide Driver is available for Android and iOS platforms</p> */}
                {/* <div className="total_btn left_iride_ptag1">
                        
                        <img src={Google_paly} onClick={this.google_playbutnwork.bind(this)} alt="not" className={'goole_play_cssbutn'}/>
                       
                        
                        <img src={Apple_paly} alt="not" onClick={this.apple_playbutnwork.bind(this)} className={'goole_play_cssbutn'}/>
                
                </div>  */}
                {/* <p className="left_iride_ptag1" data-aos="fade-up-right"><strong>iRide Passenger</strong> is the online taxi riding service. This app is used at the passenger end of the iRide taxi service. Passengers looking for taxis in the vicinity can find taxis through this app. iRidePassenger has features of advanced booking of taxis for a future date, emergency options, trip management, driver and vehicle preferences etc</p> */}
                <div className="total_btn left_iride_ptag1">
                
              
                        
              <img src={Google_paly} onClick={this.google_playbutnwork.bind(this)} alt="not" className={'goole_play_cssbutn'}/>
             
              
              <img src={Apple_paly} alt="not" onClick={this.apple_playbutnwork.bind(this)} className={'goole_play_cssbutn'}/>
        
       
                        {/* <a href="https://itunes.apple.com/us/app/iride-driver/id1326527505?mt=8">
                     <div class="group_btn_ibra" >
                    <i class="fa group_btn_ibra_icon fa-apple fa-3x pull-left" aria-hidden="true"></i>
                   
                    <span class="btn-caption">App Store</span>
                   
                </div></a>
               
                <div class="group_btn_ibra padd_rite">
                <a href="https://play.google.com/store/apps/details?id=com.icore.apps.iride.driver">
                    <i class="fab group_btn_ibra_icon fa-google-play fa-3x pull-left" aria-hidden="true"></i>
                  
                    <span class="btn-caption">Google Play</span>
                    </a> </div> */}
                        
                
                </div> 
            </div>

        </div>
    </div>
    {/* end fourth */} {/* third sections iRide Driver*/}
    {/* <div className="row">
    
        <div className=" mrg_bot_our_work_2sec">
            <div className="col-lg-6">

                <p className="left_iride_ptag1" data-aos="fade-up-right">iRide Passenger is the online taxi riding service. This app is used at the passenger end of the iRide taxi service. Passengers looking for taxis in the vicinity can find taxis through this app. iRidePassenger has features of advanced booking of taxis for a future date, emergency options, trip management, driver and vehicle preferences etc</p>
                <div className="total_btn left_iride_ptag1">
                        
                        <img src={Google_paly} onClick={this.google_playbutnwork.bind(this)} alt="not" className={'goole_play_cssbutn'}/>
                       
                        
                        <img src={Apple_paly} alt="not" onClick={this.apple_playbutnwork.bind(this)} className={'goole_play_cssbutn'}/>
                
                </div> 
            </div>
            <div className="col-lg-6">
                <img src={Iride_mob_pass} data-aos="fade-up-left" alt="" className="rgth_iride_img2" />
            </div>
        </div>
    </div> */}
    {/* end third */} {/* extra projects demo */} {/* fourth sections Catalina*/}
    <div className="row our-work-container">
    <div className={ 'marg_all_heading'}>
        <h2 className="about_ser_new_tags">iPortal</h2>
        <span className="about_bor_bot_serv"></span>
    </div>
        <div className=" mrg_bot_our_work_2sec ">
          
            <div className="col-lg-6">
            <p className="left_iride_ptag1" data-aos="fade-up-left">{ourWorkContents.iPortalDescription}</p>
                {/* <p className="left_iride_ptag1" data-aos="fade-up-left">This application is a resume storage and retrieval portal which is used for recruitment. iPortal is a resume management application that is used by prominent recruitment and US IT staffing companies. This portal has features to store bulk resumes, retrieving profiles from the resume pool using advanced filtering options, sending bulk mails to the candidates. It is built with all sorts of resume management functionalities for smooth functioning of the recruitment industry</p> */}

                <a href="http://iportal-test.icoretek.com/index.php?m=home" className="link_apps_our_work1">Go To : <span className="under_link_1">iPortal</span></a>
            </div>
            <div className="col-lg-6">
                <img src={iPortal} data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" alt="" className="rgth_iride_imgiportal" />
            </div>
        </div>
    </div>
    
    <div className="row our-work-container">
    <div className={ 'marg_all_heading'}>
        <h2 className="about_ser_new_tags">Catalina augmented reality app</h2>
        <span className="about_bor_bot_serv"></span>
    </div>
        <div className=" mrg_bot_our_work_2sec ">
            <div className="col-lg-6">
                <img src={Catlina_new} data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" alt="" className="rgth_iride_imgcatalina" />
            </div>
            <div className="col-lg-6">
            <p className="left_iride_ptag1">{ourWorkContents.CatalinaAPDescription}</p>
                {/* <p className="left_iride_ptag1" data-aos="fade-up-left">iCore has built an intelligent Augmented Reality application- Catalina ARF (Asset Recovery Form) for Trintas’ remote issue resolution of Assets (Printers and PCs) issues. This application helps the service technicians to resolve issues without having to transport the assets to the service facility. This application is pre-coded with the various assets’ or peripherals’ issues that can arise and its corresponding resolutions based on legacy documentations and experience
This application is installed in the smartphones of the service technicians. On pointing the camera interface of the app towards the asset, it provides numerous issue-related options and resolutions on the screen. Also, the screen can be shared with the service technicians back at the service facility to help in issue resolution on site.</p> */}
                {/* <p className="left_iride_ptag1">iCore is planning to promote this Augmented Reality app with customizations and introduce this to the industry as an independent product for other customers
</p> */}
                {/* <div className="total_btn left_iride_ptag1">
                        
                        <img src={Google_paly} onClick={this.google_playbutnwork.bind(this)} alt="not" className={'goole_play_cssbutn'}/>
                       
                        
                        <img src={Apple_paly} alt="not" onClick={this.apple_playbutnwork.bind(this)} className={'goole_play_cssbutn'}/>
                
                </div>  */}
                 <div className="total_btn left_iride_ptag1">
                 <img src={Google_paly} onClick={this.google_playworkbutn_catalina.bind(this)} alt="not" className={'goole_play_cssbutn'}/>
             
              
             <img src={Apple_paly} alt="not" onClick={this.apple_playworkbutn_catalina.bind(this)} className={'goole_play_cssbutn'}/>
                         {/* <a href="https://itunes.apple.com/us/app/catalina-arf/id1217479015?mt=8">
                     <div class="group_btn_ibra" >
                    <i class="fa group_btn_ibra_icon fa-apple fa-3x pull-left" aria-hidden="true"></i>
                    
                    <span class="btn-caption">App Store</span>
                   
                </div></a> */}
               
                {/* <div class="group_btn_ibra padd_rite">
                <a href=" https://play.google.com/store/apps/details?id=com.trintas.catalinaarf">
                    <i class="fab group_btn_ibra_icon fa-google-play fa-3x pull-left" aria-hidden="true"></i>
                  
                    <span class="btn-caption">Google Play</span>
                    </a> </div> */}
                
                </div> 
            </div>

        </div>
    </div>
    {/* end fourth */} {/* third sections iRide Driver*/}
    <div className="row our-work-container" id={ 'AR_ourwork'}>
    <div className={ 'marg_all_heading'}>
        <h2 className="about_ser_new_tags">Trintas – Admin module</h2>
        <span className="about_bor_bot_serv"></span>
    </div>
        <div className=" mrg_bot_our_work_2sec">
            <div className="col-lg-6">

                <p className="left_iride_ptag1" data-aos="fade-up-right">{ourWorkContents.trintasDescription}</p>
                

                <p className="link_apps_our_work1">Go To : <span className="under_link_1"><a href="https://www.tekntool.com/um/ui/#/" className='nav-link-underline' >Trintas – Augmented Reality app</a></span></p>
            </div>
            <div className="col-lg-6">
                <img src={Trintas_new_n} data-aos="fade-up-left" alt="" className="rgth_iride_img11" />
            </div>
        </div>
    </div>
    {/* end third */} {/* fourth sections Itrack*/}
    <div className="row our-work-container">
    <div className={ 'marg_all_heading'}>
        <h2 className="about_ser_new_tags">iTrack</h2>
        <span className="about_bor_bot_serv"></span>
    </div>
        <div className=" mrg_bot_our_work_2sec">
            <div className="col-lg-6">
                <img src={Itrack_mob} data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" alt="" className="rgth_iride_img3" />
            </div>
            <div className="col-lg-6">
            <p className="left_iride_ptag1" data-aos="fade-up-left">{ourWorkContents.iTrackDescription}</p>
                {/* <p className="left_iride_ptag1" data-aos="fade-up-left">iTrack is an incident management tool built by iCore Software Systems for effective management of issues or maintenance tasks arising in a company. Issues arising from clients or from internal stakeholders are populated in this iTrack tool. These issues are assigned to 
                the concerned technician or developer to come up with a resolution. It can be used to track the issue status, the applied resolution, the task completion time or any other updates regarding the issue. 
                The status of the issue is communicated to the concerned client through this application. iTrack has also the ability to perform as a PMS (Project Management System) by offering the client the provision to assign and report issues to the concerned developer who is responsible to 
                resolve the issues on behalf of the service providing company.</p> */}
               
                <a href="http://issuetracker.icoretek.com/login_page.php" className="link_apps_our_work1">Go To : <span className="under_link_1">iTrack</span></a>
            </div>

        </div>
    </div>
    {/* end fourth */} {/* fifth sections Trintas*/}
    <div className="row our-work-container">
    <div className={ 'marg_all_heading'}>
        <h2 className="about_ser_new_tags">PKS – Persistent knowledge solutions</h2>
        <span className="about_bor_bot_serv"></span>
    </div>
        <div className=" mrg_bot_our_work_2sec">
            <div className="col-lg-6">

                <p className="left_iride_ptag1" data-aos="fade-up-right">{ourWorkContents.pksDescription}</p>
                {/*
                <p className="left_iride_ptag1" data-aos="fade-up-right">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Perspiciatis unde omnis iste natus error sit.</p>
                <p className="left_iride_ptag1" data-aos="fade-up-right">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Perspiciatis unde omnis iste natus error sit.</p> */}
                <a href="http://132.148.82.240:8080/pks/#/home" className="link_apps_our_work1">Go To : <span className="under_link_1">Persistent Knowledge Solutions</span></a>
            </div>
            <div className="col-lg-6">
                <img src={Pks_mob} data-aos="zoom-out-left" alt="" className="rgth_iride_img5" />
            </div>
        </div>
    </div>
    {/* end fifth */} {/* fourth sections Itrack*/}
    <div className="row our-work-container">
    <div className={ 'marg_all_heading'}>
        <h2 className="about_ser_new_tags">iCore Technologies LLC</h2>
        <span className="about_bor_bot_serv"></span>
    </div>
        <div className=" mrg_bot_our_work_2sec">
            <div className="col-lg-6">
                <img src={iCoretek_logo} data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" alt="" className="rgth_iride_imgicore" />
            </div>
            <div className="col-lg-6">

                <p className="left_iride_ptag1" data-aos="fade-up-left">{ourWorkContents.licDescription}</p>
               
                <a href="https://icoretek.com/" className="link_apps_our_work1">Go To : <span className="under_link_1">iCore Technologies LLC</span></a>
            </div>

        </div>
    </div>
    {/* end fourth */}
    {/* end fourth */} {/* fifth sections Trintas*/}
    <div className="row our-work-container">
    <div className={ 'marg_all_heading'}>
        <h2 className="about_ser_new_tags">Readygrass, UK</h2>
        <span className="about_bor_bot_serv"></span>
    </div>
        <div className=" mrg_bot_our_work_2sec">
            <div className="col-lg-6">

                <p className="left_iride_ptag1" data-aos="fade-up-right">{ourWorkContents.readygrassDescription}</p>
                {/*
                <p className="left_iride_ptag1" data-aos="fade-up-right">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Perspiciatis unde omnis iste natus error sit.</p>
                <p className="left_iride_ptag1" data-aos="fade-up-right">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Perspiciatis unde omnis iste natus error sit.</p> */}
                <a href="https://readygrass.co.uk/" className="link_apps_our_work1">Go To : <span className="under_link_1">Readygrass, UK</span></a>
            </div>
            <div className="col-lg-6">
                <img src={Readycross} data-aos="zoom-out-left" alt="" className="rgth_iride_img5" />
            </div>
        </div>
    </div>
    {/* end fifth */} {/* fourth sections Itrack*/}
    <div className="row our-work-container">
    <div className={ 'marg_all_heading'}>
        <h2 className="about_ser_new_tags">ORM (Online Reputation Management) plugin</h2>
        <span className="about_bor_bot_serv"></span>
    </div>
        <div className=" mrg_bot_our_work_2sec" >
            <div className="col-lg-6">
                <img src={ORM} data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" alt="" className="rgth_iride_imgicore" />
            </div>
            <div className="col-lg-6">

                <p className="left_iride_ptag1" data-aos="fade-up-left">{ourWorkContents.ormDescription}</p>
               
                {/* <a href="http://website.icoretek.com/" className="link_apps_our_work1">Go To : <span className="under_link_1">iCore Technologies LLC</span></a> */}
            </div>

        </div>
    </div>
    {/* end fourth */} {/* fifth sections Trintas*/}
    <div className="row our-work-container">
    <div className={ 'marg_all_heading'}>
        <h2 className="about_ser_new_tags">Newfoil, UK</h2>
        <span className="about_bor_bot_serv"></span>
    </div>
        <div className=" mrg_bot_our_work_2sec">
            <div className="col-lg-6">

                <p className="left_iride_ptag1" data-aos="fade-up-right">{ourWorkContents.newfoilDescription}</p>
                {/*
                <p className="left_iride_ptag1" data-aos="fade-up-right">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Perspiciatis unde omnis iste natus error sit.</p>
                <p className="left_iride_ptag1" data-aos="fade-up-right">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Perspiciatis unde omnis iste natus error sit.</p> */}
                <a href="https://www.newfoil.co.uk/" className="link_apps_our_work1">Go To : <span className="under_link_1">Newfoil, UK</span></a>
            </div>
            <div className="col-lg-6">
                <img src={Newfoil} data-aos="zoom-out-left" alt="" className="rgth_iride_img5" />
            </div>
        </div>
    </div>
    {/* end fifth */} {/* fourth sections Itrack*/}
    <div className="row our-work-container">
    <div className={ 'marg_all_heading'}>
        <h2 className="about_ser_new_tags">Agile Advantages, USA</h2>
        <span className="about_bor_bot_serv"></span>
    </div>
        <div className=" mrg_bot_our_work_2sec" >
            <div className="col-lg-6">
                <img src={AgileAdvantage} data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" alt="" className="rgth_iride_imgicore" />
            </div>
            <div className="col-lg-6">

                <p className="left_iride_ptag1" data-aos="fade-up-left">{ourWorkContents.agileDescription}</p>
               
                <a href="https://agileadvantages.com/" className="link_apps_our_work1">Go To : <span className="under_link_1">Agile Advantages, USA</span></a>
            </div>

        </div>
    </div>
    <div className="row our-work-container">
    <div className={ 'marg_all_heading'}>
        <h2 className="about_ser_new_tags">Central Fans, UK</h2>
        <span className="about_bor_bot_serv"></span>
    </div>
        <div className=" mrg_bot_our_work_2sec">
            <div className="col-lg-6">

                <p className="left_iride_ptag1" data-aos="fade-up-right">{ourWorkContents.centralFansDescription}</p>
                {/*
                <p className="left_iride_ptag1" data-aos="fade-up-right">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Perspiciatis unde omnis iste natus error sit.</p>
                <p className="left_iride_ptag1" data-aos="fade-up-right">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Perspiciatis unde omnis iste natus error sit.</p> */}
                {/* <a href="https://www.newfoil.co.uk/" className="link_apps_our_work1">Go To : <span className="under_link_1">Newfoil, UK</span></a> */}
            </div>
            <div className="col-lg-6">
                <img src={Central_fans} data-aos="zoom-out-left" alt="" className="rgth_iride_img5" />
            </div>
        </div>
    </div>
    {/* ppf our work*/}
    <div className="row our-work-container">
    <div className={ 'marg_all_heading'}>
        <h2 className="about_ser_new_tags">Payperfix.net</h2>
        <span className="about_bor_bot_serv"></span>
    </div>
        <div className=" mrg_bot_our_work_2sec" >
            <div className="col-lg-6">
                <img src={ppfimage} data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" alt="" className="pph_omgwid rgth_iride_imgicore" />
            </div>
            <div className="col-lg-6">
                    <p  className="left_iride_ptag1" data-aos="fade-up-left">{ourWorkContents.payPerfixDescription}</p>
                {/* <p className="left_iride_ptag1" data-aos="fade-up-left">Collaborative project hiring platforms are gaining traction, especially in recent times. Companies today prefer hiring out workers who are professional, well-qualified, and economical – charging only for the task that they perform. Looking at the need to provide a lucrative, versatile, yet clear platform to hire employees for a particular project and duration, iCore Software Systems built an outsourcing market place where hirers can register and look for skills and workers can seek projects to execute. 
                The platform also accommodates simple, user-friendly signup and registration processes.</p> */}
               
                <a href="http://payperfix.net/" className="link_apps_our_work1">Go To : <span className="under_link_1">Payperfix.net</span></a>
            </div>
        </div>
    </div>
</div><br/>
            
            <div className="today_contact">
            <img id="img_logo" alt="not found" src={today_contact}/>
            <h3 className="chk">Ready for your next IT project?</h3>
            <p className="tdy_p">Unlock the potential of technology to drive success.</p>
            <Link to="/contact-us"  target="_top">
            <button type="button" className="btn btn-primary btn-sm" id="tdy_button">Get started today!</button></Link>
            </div>
</div>
    )
  }
}