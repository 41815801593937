import React  from 'react'
// import Header from './header.js'
//import Portfolio from './portfolio.js'
// import Running_Header from './running_header'
// import About from './about.js'
import Our_services from './our_service'
import Our_work_home from './our_work' 
// import Our_service_runnning from './running_header/our_service_running.js'

import './home.css';
import Swiper from './swiper_slider.js';
import { BrowserRouter as Router, Switch, Route,Link } from 'react-router-dom';
import Footer from './footer.js';
import DocumentMeta from 'react-document-meta';
import {Helmet} from "react-helmet";
// import Helmet from 'react-helmet';
import Testimonial from './testimonial.js'
import Contactus from './contact.js'
import Ourclient from './our_client.js'
import Port from './extra_port.js'  
import About_Phone from './about_phone.js'
// import About_Phone_header from './running_header/about_phone_head.js'
import Color from './color_bg.js'
import Connection_img_new from './img_new/videos/connection1.png'
import Header_img_new from './img_new/videos/banner22.png'
import 'react-app-polyfill/ie11';
import $ from 'jquery'
export default class Home extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            headerContent: {}
        }
    }
    componentDidMount() {
        $(document).ready(function () {
            $('.nav_bar_adding_home').addClass('active_cus');
        });
        const headerContent = {
            subTitle: "AI is transforming industries, fueling productivity and expansion.We design cutting-edge solutions to elevate performance. From advanced AI platforms to tailored applications, we propel your business forward. Bringing your vision to life with innovative precision and expertise"
        }
        this.setState({headerContent})
    }

    render(){
        const { headerContent } = this.state
        return(    
            <div>
                <Helmet>
                   <meta charSet="utf-8" />
                   <title>Web & Mobile App Development | UI UX Development Company in Texas</title>
                   <meta name="description" content="iCore software systems is an expert in web and mobile app development. We are a UI UX development company in Texas with an innovative mindset." />
                   <link rel="canonical" href="https://www.icoresoftwaresystems.com/" />
                </Helmet>
            <button>click</button>

             <div className='bg_color'>
        <header className="header ovelay_homehead valign bg-img" data-scroll-index="0" data-overlay-dark="5"  data-stellar-background-ratio="0.5">
           <img className="bg-vid  video_bg_header" src={Header_img_new} alt="not-found"/> 
       
        <div className="container home-page-container">
            <div className="row resp-home-header">
                                <div className="col-lg-6 resp-header-images">
                                    <div className="caption ">
                                        <h2 className="cd-headline clip mrg_top_home_ie mrg_top_seo checking">
                                            <span className="blc for_header_txt">Unlocking Business Potential with AI-Driven Excellence</span>
                                        </h2>
                                        <span className="blc for_header_txt_secondblock para">{headerContent.subTitle}</span><br /><br />

                                        <Link to='/contact-us'><button type="button" className="Rectangle-8 chkk">Get in Touch Today!</button></Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div className="col-lg-6 home-image">
                                    <img className="img_chk resp-header-img" src={Connection_img_new} alt="not-found" />
                                </div>
                            </div>
                        </div>

        {/* <div className="arrow">
        <a href="#" >
            <i className="fa fa-chevron-down bounce"></i>
        </a>
        </div> */}
        </header> 
            </div></div>
        )
    }
}