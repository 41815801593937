import React from 'react'
import './our_service_page/our_service_inner.css'
import Our_service_ecommerce from './our_service_page/our_service_ecommerce.js'
import Our_service_rotate_img from './our_service_page/our_service_rotate_img.js'
import Our_Service_Inner from './our_service_page/our_service_inner.js'
import Our_Service_Mobile from './our_service_page/our_service_mobile.js'
import Our_Service_us_staffing from './our_service_page/our_service_us_staffing.js'
import Our_service_web_design from './our_service_page/our_service_web_design.js'
import DocumentMeta from 'react-document-meta';
import {Helmet} from "react-helmet";
// import Helmet from 'react-helmet';
import Footer from './footer.js'
import Augmented_inner from './our_service_page/augmented_inner.js'
import Seo_inner from './our_service_page/seo.js'
import $ from 'jquery'
export default class Our_Service_Inner_Connect extends React.Component{
      componentDidMount=() =>{
        window.scrollTo(0, 0)
        // $(document).keydown(function (event) {
        //     if (event.keyCode == 123) { // Prevent F12
        //         return false;
        //     } else if (event.ctrlKey && event.shiftKey && event.keyCode == 73) { // Prevent Ctrl+Shift+I        
        //         return false;
        //     }
        // });
        // $(document).on("contextmenu", function (e) {        
        //     e.preventDefault();
        // });
        $(document).ready(function() {
            $('.nav_bar_adding_ourservice').addClass('active_cus');
        });
}      
  render(){
      const currentPath = window.location.pathname;
      const canonicalUrl = `https://www.icoresoftwaresystems.com${currentPath}`;
    /* const meta = {
        title: 'Augmented Reality App Companies in Texas | Online Reputation Management Plugin | our-service',
        description: 'iCore Software Systems is a technology-driven web design and development company that has extended its capabilities to web application development, building customized eCommerce solutions and mobile applications',
        canonical: 'https://www.icoresoftwaresystems.com/our-service',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'Technology-driven, Problem-solving, Excellence, Customer-focus, Accountability'
            }
        }
    }*/
    return(
                /*<DocumentMeta {...meta}/>*/
            <div>
                <Helmet>
                   <meta charSet="utf-8" />
                   <title>Augmented Reality App Companies in Texas | Online Reputation Management Plugin | our-service</title>
                   <meta name="description" content="Being an advanced augmented Reality App company in Texas, we have an enhanced plugin for Online Reputation Management, ensuring smoothness in business." />
                   <link rel="canonical" href={canonicalUrl} />
                   
                </Helmet>
 
{/* end */}
<Our_Service_Inner/>
<Our_service_web_design/>
<Our_service_rotate_img/>
<Our_service_ecommerce/>
<Our_Service_Mobile/>
<Augmented_inner/>
<Seo_inner/> 
<Our_Service_us_staffing/>
<Footer/>
            </div>
  
    )
  }
}