// import React  from 'react'
// import './extra_video.css';
// import './home.css';
// import Extra_Video_new from './img_new/videos/extra_video1.mp4'
// export default class Extra_Videos extends React.Component{
//     componentDidMount(){
//         // this.timer=setInterval(this.tick,30000);
//         // setTimeout(()=>{this.setState(a())},3000);
//         function   myFunction() {
//             var repeat = true;
           
//                 var parent = document.getElementById("div1");
//                 var child = document.getElementById("div2");
    
//                 // parent.removeChild(child);
            
//         }
//        var i=0;
//        var interval=setInterval(function(){
//         var parent = document.getElementById("div1");
//         var child = document.getElementById("div2");
//         myFunction();
//         // parent.appendChild(child);
//     console.log(i++);
//        },20000)
       
        
//     }
//     componentWillMount(){
//         clearInterval(this.timer);
//     }  

//     render(){
//         return(
//             <div>
                
          
       
//   <div id="outer" className="">
//     <div id="home-top-video">
//       <video autoplay loop muted width="100%" playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
//         <source src={Extra_Video_new} type="video/mp4" /> Your browser does not support the video tag. We suggest you upgrade your browser.
//       </video>
//       <div id="home-text_about_new">
//         <h2 style={{textAlign:'center'}}>OUR&nbsp;CAPABILITIES</h2>
//         {/* <div class="text_content">
//            <div class="sp-container_point" id="div1">
//         <div class="sp-content_drop" id="div2">
//             <div class="sp-globe"></div>
//             <h2 class="frame-1">OUR&nbsp;CAPABILITIES </h2>
//             <h2 class="frame-2">OUR&nbsp;CAPABILITIES </h2>
//             <h2 class="frame-3">OUR&nbsp;CAPABILITIES </h2>
//             <h2 class="frame-4">OUR&nbsp;CAPABILITIES </h2>
//             <h2 class="frame-5">
//                 <span>OUR&nbsp;CAPABILITIES </span>
                
//         </h2></div></div></div> */}
//       </div>
//     </div>
//   </div>
// </div>       
//             )
//         }
//     }
import React  from 'react'
import './extra_video.css';

// import AOS from 'aos'
// import Our_cap_left from './img_new/our_cap_left1.png'
// import Our_cap_right from './img_new/our_cap_right1.png'
// import './home.css';
// import Extra_Video_new from './img_new/videos/extra_video1.mp4'
export default class Home extends React.Component{
    componentDidMount(){
    }
    render(){
        return(    
            <div>
   
    {/* <h2 className="about_ser_new_tags">Our Capabilities</h2>
    <span className="about_bor_bot_serv"></span> */}
</div>   
        )
    }
}