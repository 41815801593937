// import React  from 'react'
// import './about_us_inner_philosophy.css';
// import AOS from 'aos'
// import Philosophy_img1 from '../img_new/about_us_inner/our_piloshopy1.png'
// import Philosophy_img2 from '../img_new/about_us_inner/our_piloshopy2.jpg'
// import Philosophy_img3 from '../img_new/about_us_inner/our_piloshopy3.jpg'
// import Philosophy_img4 from '../img_new/about_us_inner/our_piloshopy6.jpg'
// import Philosophy_img5 from '../img_new/about_us_inner/our_piloshopy4.png'
// import Philosophy_img6 from '../img_new/about_us_inner/our_piloshopy5.jpg'
// export default class About_us_inner_philosophy extends React.Component{
//     componentDidMount(){
       
//         AOS.init();
//     }
//     render(){
//         return(    
//             <div>
//          {/* header */}
//          <section id="" class="" style={{marginTop:'1%',paddingTop:'0px'}}>
//             <div class="container">
//                 <div class="row justify-content-center">
//                     <div class="col-12 col-md-12 text-center">
//                         <h3 className="head_bor_inner3">Our Business Principles</h3>
//                         <p><br/></p>
//                     </div>
//                 </div>
//             </div>
//         </section>
            
//                 <div className="container-fulid only_web_view_inner_philosophy">
//                     <div className="row">
//                         <div className="col-lg-12">
//                             <div className="col-lg-6 mrg_10_inner_phis left_2_bg_clr_inner">
//                             <h3 className="txt_head_philosophy_inner1">Value Addition and Value Proposition</h3>
//                                 <p className="hvr_fixed_text_inner clr_black_inner" data-aos="fade-right">iCore Software Systems strives to fulfil the client requirements and eliminate their pain points through our seamless work ethic. Apart from meeting client requirements, we work smart to provide relevant and ample value addition along with our delivery. This aspect of us enhances the value proposition that we offer to our clients which ensures client retention, convertible references and generate multiple streams of recurring revenue</p>
                         
                            
//                                 {/* <p className="hvr_fixed_text_inner clr_black_inner" data-aos="fade-right">2Hover the mouse pointer over this paragraph Many thanks guys, much appreciated. they both work great but I went with @Rory's answer in the end as it means I can do multiple 'state' divs without having to repeat the jquery over and over. I'm sure they'll both come .</p> */}
                           
                            
//                                 {/* <p className="hvr_fixed_text_inner clr_black_inner" data-aos="fade-right">3Hover the mouse pointer over this paragraph Many thanks guys, much appreciated. they both work great but I went with @Rory's answer in the end as it means I can do multiple 'state' divs without having to repeat the jquery over and over. I'm sure they'll both come .</p> */}
                            
//                             </div>
//                             <div className="col-lg-6 pad_zero_inner">
//                                 <div className="">
//                                 <img src={Philosophy_img1} className="bg_philosopy1" />
//                                 </div>
//                             </div>
//                         </div>
//                         {/* second */}
//                         <div className="col-lg-12 bg_sepr_philosophy_inner2" >
//                             <div className="col-lg-6 pad_zero_inner">
//                             <div className="">
//                                     <img src={Philosophy_img2} className="bg_philosopy2" />
//                             </div>
//                             </div>
//                             <div className="col-lg-6 mrg_10_inner_phis left_1_bg_clr_inner" >
//                             <h3 className="clr_white txt_head_philosophy_inner2">Deep Analysis and Solution Delivery</h3>
//                                 <p className="hvr_fixed_text_inner " data-aos="fade-right">Our approach of handling the customer pain points involves precise requirement gathering, deep analysis and crafting tailor-made solutions. Our trait of excellence begins from requirement gathering which is obtained carefully from our client along with customer problems</p>
                          
                            
//                                 <p className="hvr_fixed_text_inner " data-aos="fade-right">A detailed analysis of the requirements gathered is conducted by our team of experts coerced with the customer problems recorded. Based on the findings of the analysis, our solution architects build carefully crafted solutions or products and deliver them to the clients</p>
                            
                            
//                                 {/* <p className="hvr_fixed_text_inner " data-aos="fade-right">3Hover the mouse pointer over this paragraph Many thanks guys, much appreciated. they both work great but I went with @Rory's answer in the end as it means I can do multiple 'state' divs without having to repeat the jquery over and over. I'm sure they'll both come .</p> */}
                            
//                             </div>
//                         </div>
//                         {/* third */}
//                         <div className="col-lg-12 bg_sepr_philosophy_inner3">
//                             <div className="col-lg-6 mrg_10_inner_phis left_3_bg_clr_inner3">
//                             <h3 className="txt_head_philosophy_inner3 clr_white">The Partnership Model</h3>
//                                 <p className="hvr_fixed_text_inner" data-aos="fade-right">Our values of accountability and excellence is well recognized by our clients and hence they consider us as their trusted partners in problem solving. We respect their consideration and follow the partnership model in designing solutions to them. Due to a mutual feeling of strong partnership between us and clients, they turn to us for any kind of technology advice to cross operational hurdles with ease</p>
                           
                            
//                                 {/* <p className="hvr_fixed_text_inner" data-aos="fade-right">2Hover the mouse pointer over this paragraph Many thanks guys, much appreciated. they both work great but I went with @Rory's answer in the end as it means I can do multiple 'state' divs without having to repeat the jquery over and over. I'm sure they'll both come .</p> */}
                            
                            
//                                 {/* <p className="hvr_fixed_text_inner" data-aos="fade-right">3Hover the mouse pointer over this paragraph Many thanks guys, much appreciated. they both work great but I went with @Rory's answer in the end as it means I can do multiple 'state' divs without having to repeat the jquery over and over. I'm sure they'll both come .</p> */}
                            
//                             </div>
//                             <div className="col-lg-6 pad_zero_inner">
//                                 <div className="">
//                                 <img src={Philosophy_img3} className="bg_philosopy3" />
//                                 </div>
//                             </div>
//                         </div>
//                         {/* 4TH ROW */}
//                         <div className="col-lg-12 bg_sepr_philosophy_inner4" >
//                             <div className="col-lg-6 pad_zero_inner">
//                             <div className="">
//                                     <img src={Philosophy_img4} className="bg_philosopy2" />
//                             </div>
//                             </div>
//                             <div className="col-lg-6 mrg_10_inner_phis left_4_bg_clr_inner" >
//                             <h3 className="clr_white txt_head_philosophy_inner4">Quality Management Systems and Information Security</h3>
//                                 <p className="hvr_fixed_text_inner " data-aos="fade-right">iCore Software Systems is very particular about the quality of work delivered. As one of our prime values is ‘Excellence’, we have built state of the art Quality Management Systems and follow industry’s best quality practices to keep us and our clients ahead of the game</p>
                          
                            
//                                 <p className="hvr_fixed_text_inner " data-aos="fade-right">We also follow stringent security protocols in all aspects of our functioning to honour the confidentiality of our client’s information and data that we encounter</p>
                            
                            
//                                 {/* <p className="hvr_fixed_text_inner " data-aos="fade-right">3Hover the mouse pointer over this paragraph Many thanks guys, much appreciated. they both work great but I went with @Rory's answer in the end as it means I can do multiple 'state' divs without having to repeat the jquery over and over. I'm sure they'll both come .</p> */}
                            
//                             </div>
//                         </div>
//                         {/* 5th row start */}
//                         <div className="col-lg-12 bg_sepr_philosophy_inner3 left_3_bg_clr_inner3    ">
//                             <div className="col-lg-6 mrg_10_inner_phis ">
//                             <h3 className="txt_head_philosophy_inner_new clr_white">Client Relationship and Trust</h3>
//                                 <p className="hvr_fixed_text_inner" data-aos="fade-right">Strong customer focus, building customer centric solutions and our confidence in execution of our business has earned us immense trust and respect from our clients. Our professional relationship with each and every client of us is built with the fundamentals of trust. This has enabled us to build valued client relationships and gain repetitive opportunities from clients and other references in the industry</p>
                           
                            
//                                 {/* <p className="hvr_fixed_text_inner" data-aos="fade-right">2Hover the mouse pointer over this paragraph Many thanks guys, much appreciated. they both work great but I went with @Rory's answer in the end as it means I can do multiple 'state' divs without having to repeat the jquery over and over. I'm sure they'll both come .</p> */}
                            
                            
//                                 {/* <p className="hvr_fixed_text_inner" data-aos="fade-right">3Hover the mouse pointer over this paragraph Many thanks guys, much appreciated. they both work great but I went with @Rory's answer in the end as it means I can do multiple 'state' divs without having to repeat the jquery over and over. I'm sure they'll both come .</p> */}
                            
//                             </div>
//                             <div className="col-lg-6 pad_zero_inner">
//                                 <div className="">
//                                 <img src={Philosophy_img5} className="bg_philosopy3" />
//                                 </div>
//                             </div>
//                         </div>
//                         {/* 4TH ROW */}
//                         <div className="col-lg-12 bg_sepr_philosophy_inner4" >
//                             <div className="col-lg-6 pad_zero_inner">
//                             <div className="">
//                                     <img src={Philosophy_img6} className="bg_philosopy2" />
//                             </div>
//                             </div>
//                             <div className="col-lg-6 mrg_10_inner_phis left_4_bg_clr_inner" >
//                             <h3 className="clr_white txt_head_philosophy_inner_new2">Futuristic Approach</h3>
//                                 <p className="hvr_fixed_text_inner " data-aos="fade-right">We strongly believe that limits of our expertise do not end at crafting solutions just as per the client requirements. But our solutions and advice extends furthermore to keep our clients equipped to meet future technological advancements.  This is possible by us due to our ability to foresee the technological advancements and deep analysis that we conduct before building the solutions</p>
                          
                            
//                                 {/* <p className="hvr_fixed_text_inner " data-aos="fade-right">We also follow stringent security protocols in all aspects of our functioning to honour the confidentiality of our client’s information and data that we encounter</p> */}
                            
                            
//                                 {/* <p className="hvr_fixed_text_inner " data-aos="fade-right">3Hover the mouse pointer over this paragraph Many thanks guys, much appreciated. they both work great but I went with @Rory's answer in the end as it means I can do multiple 'state' divs without having to repeat the jquery over and over. I'm sure they'll both come .</p> */}
                            
//                             </div>
//                         </div>
//                     </div>
//                 </div>
           
//                 {/* FOR MOBILE VIEW ONLY */}
//                 <div className="container-fulid only_mobile_view_inner_philosophy">
//                     <div className="row">
//                         <div className="col-lg-12">
//                         <div className="col-lg-6 pad_zero_inner">
//                                 <div className="">
//                                 <img src={Philosophy_img1} className="bg_philosopy1" />
//                                 </div>
//                             </div>
//                             <div className="col-lg-6 mrg_10_inner_phis left_2_bg_clr_inner">
//                             <h3 className="txt_head_philosophy_inner1">Value Addition and Value Proposition</h3>
//                                 <p className="hvr_fixed_text_inner clr_black_inner" data-aos="fade-right">iCore Software Systems strives to fulfil the client requirements and eliminate their pain points through our seamless work ethic. Apart from meeting client requirements, we work smart to provide relevant and ample value addition along with our delivery. This aspect of us enhances the value proposition that we offer to our clients which ensures client retention, convertible references and generate multiple streams of recurring revenue</p>
                         
                            
//                                 {/* <p className="hvr_fixed_text_inner clr_black_inner" data-aos="fade-right">2Hover the mouse pointer over this paragraph Many thanks guys, much appreciated. they both work great but I went with @Rory's answer in the end as it means I can do multiple 'state' divs without having to repeat the jquery over and over. I'm sure they'll both come .</p> */}
                           
                            
//                                 {/* <p className="hvr_fixed_text_inner clr_black_inner" data-aos="fade-right">3Hover the mouse pointer over this paragraph Many thanks guys, much appreciated. they both work great but I went with @Rory's answer in the end as it means I can do multiple 'state' divs without having to repeat the jquery over and over. I'm sure they'll both come .</p> */}
                            
//                             </div>
                          
//                         </div>
//                         {/* second */}
//                         <div className="col-lg-12 bg_sepr_philosophy_inner2">
//                         <div className="col-lg-6 pad_zero_inner">
//                             <div className="">
//                                     <img src={Philosophy_img2} className="bg_philosopy2" />
//                             </div>
//                             </div>
//                             <div className="col-lg-6 mrg_10_inner_phis left_1_bg_clr_inner" >
//                             <h3 className="clr_white txt_head_philosophy_inner2">Deep Analysis and Solution Delivery</h3>
//                                 <p className="hvr_fixed_text_inner " data-aos="fade-right">Our approach of handling the customer pain points involves precise requirement gathering, deep analysis and crafting tailor-made solutions. Our trait of excellence begins from requirement gathering which is obtained carefully from our client along with customer problems</p>
                          
                            
//                                 <p className="hvr_fixed_text_inner " data-aos="fade-right">A detailed analysis of the requirements gathered is conducted by our team of experts coerced with the customer problems recorded. Based on the findings of the analysis, our solution architects build carefully crafted solutions or products and deliver them to the clients</p>
                            
                            
//                                 {/* <p className="hvr_fixed_text_inner " data-aos="fade-right">3Hover the mouse pointer over this paragraph Many thanks guys, much appreciated. they both work great but I went with @Rory's answer in the end as it means I can do multiple 'state' divs without having to repeat the jquery over and over. I'm sure they'll both come .</p> */}
                            
//                             </div>
                            
//                         </div>
//                         {/* third */}
//                         <div className="col-lg-12 bg_sepr_philosophy_inner3">
//                         <div className="col-lg-6 pad_zero_inner">
//                                 <div className="">
//                                 <img src={Philosophy_img3} className="bg_philosopy3" />
//                                 </div>
//                             </div>
//                             <div className="col-lg-6 mrg_10_inner_phis left_3_bg_clr_inner">
//                             <h3 className="clr_white txt_head_philosophy_inner3">The Partnership Model</h3>
//                                 <p className="hvr_fixed_text_inner" data-aos="fade-right">Our values of accountability and excellence is well recognized by our clients and hence they consider us as their trusted partners in problem solving. We respect their consideration and follow the partnership model in designing solutions to them. Due to a mutual feeling of strong partnership between us and clients, they turn to us for any kind of technology advice to cross operational hurdles with ease</p>
                           
                            
//                                 {/* <p className="hvr_fixed_text_inner" data-aos="fade-right">2Hover the mouse pointer over this paragraph Many thanks guys, much appreciated. they both work great but I went with @Rory's answer in the end as it means I can do multiple 'state' divs without having to repeat the jquery over and over. I'm sure they'll both come .</p> */}
                            
                            
//                                 {/* <p className="hvr_fixed_text_inner" data-aos="fade-right">3Hover the mouse pointer over this paragraph Many thanks guys, much appreciated. they both work great but I went with @Rory's answer in the end as it means I can do multiple 'state' divs without having to repeat the jquery over and over. I'm sure they'll both come .</p> */}
                            
//                             </div>
                           
//                         </div>
//                         {/* 4th row */}
//                         <div className="col-lg-12 bg_sepr_philosophy_inner4" >
//                             <div className="col-lg-6 pad_zero_inner">
//                             <div className="">
//                                     <img src={Philosophy_img4} className="bg_philosopy2" />
//                             </div>
//                             </div>
//                             <div className="col-lg-6 mrg_10_inner_phis left_4_bg_clr_inner" >
//                             <h3 className="clr_white txt_head_philosophy_inner4">Quality Management Systems and Information Security</h3>
//                                 <p className="hvr_fixed_text_inner " data-aos="fade-right">iCore Software Systems is very particular about the quality of work delivered. As one of our prime values is ‘Excellence’, we have built state of the art Quality Management Systems and follow industry’s best quality practices to keep us and our clients ahead of the game</p>
                          
                            
//                                 <p className="hvr_fixed_text_inner " data-aos="fade-right">We also follow stringent security protocols in all aspects of our functioning to honour the confidentiality of our client’s information and data that we encounter</p>
                            
                            
//                                 {/* <p className="hvr_fixed_text_inner " data-aos="fade-right">3Hover the mouse pointer over this paragraph Many thanks guys, much appreciated. they both work great but I went with @Rory's answer in the end as it means I can do multiple 'state' divs without having to repeat the jquery over and over. I'm sure they'll both come .</p> */}
                            
//                             </div>
//                         </div>
// {/* end */}
//                     </div>
//                     <div className="col-lg-12 pad_zero_inner_lastone bg_sepr_philosophy_inner3">
//                         <div className="col-lg-6 pad_zero_inner">
//                                 <div className="">
//                                 <img src={Philosophy_img5} className="bg_philosopy3" />
//                                 </div>
//                             </div>
//                             <div className="col-lg-6 mrg_10_inner_phis left_3_bg_clr_inner">
//                             <h3 className="clr_white txt_head_philosophy_inner_new">Client Relationship and Trust</h3>
//                                 <p className="hvr_fixed_text_inner" data-aos="fade-right">Strong customer focus, building customer centric solutions and our confidence in execution of our business has earned us immense trust and respect from our clients. Our professional relationship with each and every client of us is built with the fundamentals of trust. This has enabled us to build valued client relationships and gain repetitive opportunities from clients and other references in the industry</p>
                           
                            
//                                 {/* <p className="hvr_fixed_text_inner" data-aos="fade-right">2Hover the mouse pointer over this paragraph Many thanks guys, much appreciated. they both work great but I went with @Rory's answer in the end as it means I can do multiple 'state' divs without having to repeat the jquery over and over. I'm sure they'll both come .</p> */}
                            
                            
//                                 {/* <p className="hvr_fixed_text_inner" data-aos="fade-right">3Hover the mouse pointer over this paragraph Many thanks guys, much appreciated. they both work great but I went with @Rory's answer in the end as it means I can do multiple 'state' divs without having to repeat the jquery over and over. I'm sure they'll both come .</p> */}
                            
//                             </div>
                           
//                         </div>
//                         {/* end */}
//                         <div className="col-lg-12 pad_zero_inner_lastone bg_sepr_philosophy_inner4">
//                         <div className="col-lg-6 pad_zero_inner">
//                                 <div className="">
//                                 <img src={Philosophy_img6} className="bg_philosopy3" />
//                                 </div>
//                             </div>
//                             <div className="col-lg-6 mrg_10_inner_phis ">
//                             <h3 className="clr_white txt_head_philosophy_inner_new2">Futuristic Approach</h3>
//                                 <p className="hvr_fixed_text_inner" data-aos="fade-right">We strongly believe that limits of our expertise do not end at crafting solutions just as per the client requirements. But our solutions and advice extends furthermore to keep our clients equipped to meet future technological advancements.  This is possible by us due to our ability to foresee the technological advancements and deep analysis that we conduct before building the solutions</p>
                           
                            
//                                 {/* <p className="hvr_fixed_text_inner" data-aos="fade-right">2Hover the mouse pointer over this paragraph Many thanks guys, much appreciated. they both work great but I went with @Rory's answer in the end as it means I can do multiple 'state' divs without having to repeat the jquery over and over. I'm sure they'll both come .</p> */}
                            
                            
//                                 {/* <p className="hvr_fixed_text_inner" data-aos="fade-right">3Hover the mouse pointer over this paragraph Many thanks guys, much appreciated. they both work great but I went with @Rory's answer in the end as it means I can do multiple 'state' divs without having to repeat the jquery over and over. I'm sure they'll both come .</p> */}
                            
//                             </div>
                           
//                         </div>
//                 </div>
           



//             </div>
            
//         )
//     }
// }

import React  from 'react'
import './about_us_inner_philosophy.css';
import $ from 'jquery';
// import AOS from 'aos'
import Collapsible from 'react-collapsible';
// import Philosophy_img1 from '../img_new/about_us_inner/our_piloshopy1.png'
// import Philosophy_img2 from '../img_new/about_us_inner/our_piloshopy2.jpg'
// import Philosophy_img3 from '../img_new/about_us_inner/our_piloshopy3.jpg'
// import Philosophy_img4 from '../img_new/about_us_inner/our_piloshopy6.jpg'
// import Philosophy_img5 from '../img_new/about_us_inner/our_piloshopy4.png'
// import Philosophy_img6 from '../img_new/about_us_inner/our_piloshopy5.jpg'
export default class About_us_inner_philosophy extends React.Component{
    constructor(props){
        super(props);
          this.state = {
             titles: {},
             description: {}
          }
    }
            componentDidMount(){
            // AOS.init();      // content
        //     document.getElementById("circle2").style.display = "none"; 
        //     document.getElementById("circle3").style.display = "none"; 
        //     document.getElementById("circle4").style.display = "none"; 
        //     document.getElementById("circle5").style.display = "none"; 
        //     document.getElementById("circle6").style.display = "none"; 
        //                      // images
        //     document.getElementById("img2").style.display = "none";
        //     document.getElementById("img3").style.display = "none";
        //     document.getElementById("img4").style.display = "none";
        //     document.getElementById("img5").style.display = "none";
        //     document.getElementById("img6").style.display = "none";
        //     document.getElementById("btn_one").onclick = function() { 
        //     document.getElementById("circle").style.display = "block"; 
        //     document.getElementById("circle2").style.display = "none";
        //     document.getElementById("circle3").style.display = "none";
        //     document.getElementById("circle4").style.display = "none";
        //     document.getElementById("circle5").style.display = "none";
        //     document.getElementById("circle6").style.display = "none"; 
        //     document.getElementById("img1").style.display = "block";
        //     document.getElementById("img2").style.display = "none";
        //     document.getElementById("img3").style.display = "none";
        //     document.getElementById("img4").style.display = "none";
        //     document.getElementById("img5").style.display = "none";
        //     document.getElementById("img6").style.display = "none";
        //     document.getElementById("btn_one").style.color = "#101f7d";
        //     document.getElementById("btn_two").style.color = "#312e2e";
        //     document.getElementById("btn_three").style.color = "#312e2e";
        //     document.getElementById("btn_four").style.color = "#312e2e";
        //     document.getElementById("btn_five").style.color = "#312e2e";
        //     document.getElementById("btn_six").style.color = "#312e2e";
        //     } 
        //     document.getElementById("btn_two").onclick = function() { 
        //     document.getElementById("circle").style.display = "none"; 
        //     document.getElementById("circle2").style.display = "block"; 
        //     document.getElementById("circle3").style.display = "none"; 
        //     document.getElementById("circle4").style.display = "none"; 
        //     document.getElementById("circle5").style.display = "none"; 
        //     document.getElementById("circle6").style.display = "none";
        //     document.getElementById("img2").style.display = "block";
        //     document.getElementById("img1").style.display = "none";
        //     document.getElementById("img3").style.display = "none";
        //     document.getElementById("img4").style.display = "none";
        //     document.getElementById("img5").style.display = "none";
        //     document.getElementById("img6").style.display = "none";
        //     document.getElementById("btn_one").style.color = "#312e2e";
        //     document.getElementById("btn_two").style.color = "#101f7d";
        //     document.getElementById("btn_three").style.color = "#312e2e";
        //     document.getElementById("btn_four").style.color = "#312e2e";
        //     document.getElementById("btn_five").style.color = "#312e2e";
        //     document.getElementById("btn_six").style.color = "#312e2e";    
        //     } 
        //     document.getElementById("btn_three").onclick = function() { 
        //     document.getElementById("circle").style.display = "none"; 
        //     document.getElementById("circle2").style.display = "none"; 
        //     document.getElementById("circle3").style.display = "block";
        //     document.getElementById("circle4").style.display = "none"; 
        //     document.getElementById("circle5").style.display = "none"; 
        //     document.getElementById("circle6").style.display = "none";
        //     document.getElementById("img1").style.display = "none";
        //     document.getElementById("img2").style.display = "none";
        //     document.getElementById("img3").style.display = "block";
        //     document.getElementById("img4").style.display = "none";
        //     document.getElementById("img5").style.display = "none";
        //     document.getElementById("img6").style.display = "none";
        //     document.getElementById("btn_three").style.color = "#101f7d";
        //     document.getElementById("btn_two").style.color = "#312e2e";
        //     document.getElementById("btn_one").style.color = "#312e2e";
        //     document.getElementById("btn_four").style.color = "#312e2e";
        //     document.getElementById("btn_five").style.color = "#312e2e";
        //     document.getElementById("btn_six").style.color = "#312e2e";   
        //     } 
        //     document.getElementById("btn_four").onclick = function() { 
        //     document.getElementById("circle").style.display = "none"; 
        //     document.getElementById("circle2").style.display = "none"; 
        //     document.getElementById("circle3").style.display = "none"; 
        //     document.getElementById("circle4").style.display = "block"; 
        //     document.getElementById("circle5").style.display = "none"; 
        //     document.getElementById("circle6").style.display = "none";
        //     document.getElementById("img1").style.display = "none";
        //     document.getElementById("img2").style.display = "none";
        //     document.getElementById("img3").style.display = "none";
        //     document.getElementById("img4").style.display = "block";
        //     document.getElementById("img5").style.display = "none";
        //     document.getElementById("img6").style.display = "none";
        //     document.getElementById("btn_four").style.color = "#101f7d";
        //     document.getElementById("btn_three").style.color = "#312e2e";
        //     document.getElementById("btn_two").style.color = "#312e2e";
        //     document.getElementById("btn_one").style.color = "#312e2e";
        //     document.getElementById("btn_five").style.color = "#312e2e";
        //     document.getElementById("btn_six").style.color = "#312e2e";   
        //     } 
        //     document.getElementById("btn_five").onclick = function() { 
        //     document.getElementById("circle").style.display = "none"; 
        //     document.getElementById("circle2").style.display = "none"; 
        //     document.getElementById("circle3").style.display = "none"; 
        //     document.getElementById("circle4").style.display = "none"; 
        //     document.getElementById("circle5").style.display = "block"; 
        //     document.getElementById("circle6").style.display = "none"; 
        //     document.getElementById("img1").style.display = "none";
        //     document.getElementById("img2").style.display = "none";
        //     document.getElementById("img3").style.display = "none";
        //     document.getElementById("img4").style.display = "none";
        //     document.getElementById("img5").style.display = "block";
        //     document.getElementById("img6").style.display = "none";
        //     document.getElementById("btn_five").style.color = "#101f7d";
        //     document.getElementById("btn_one").style.color = "#312e2e";
        //     document.getElementById("btn_two").style.color = "#312e2e";
        //     document.getElementById("btn_three").style.color = "#312e2e";
        //     document.getElementById("btn_four").style.color = "#312e2e";
        //     document.getElementById("btn_six").style.color = "#312e2e";      
        //     } 
        //     document.getElementById("btn_six").onclick = function() { 
        //     document.getElementById("circle").style.display = "none"; 
        //     document.getElementById("circle2").style.display = "none"; 
        //     document.getElementById("circle3").style.display = "none"; 
        //     document.getElementById("circle4").style.display = "none"; 
        //     document.getElementById("circle5").style.display = "none"; 
        //     document.getElementById("circle6").style.display = "block"; 
        //     document.getElementById("img1").style.display = "none";
        //     document.getElementById("img2").style.display = "none";
        //     document.getElementById("img3").style.display = "none";
        //     document.getElementById("img4").style.display = "none";
        //     document.getElementById("img5").style.display = "none";
        //     document.getElementById("img6").style.display = "block"; 
        //     document.getElementById("btn_six").style.color = "#101f7d";
        //     document.getElementById("btn_five").style.color = "#312e2e";
        //     document.getElementById("btn_one").style.color = "#312e2e";
        //     document.getElementById("btn_two").style.color = "#312e2e";
        //     document.getElementById("btn_three").style.color = "#312e2e";
        //     document.getElementById("btn_four").style.color = "#312e2e";   
        // } 
        const titles = {
            solutionTitle: "Delivering Value-Driven Technology Solutions",
            valueTitle: "Value-Centered Solutions",
            comprehensiveTitle: "Holistic Problem-Solving",
            collaborativeTitle : "Collaborative Partnerships",
            qualityTitle: "Commitment to Quality",
            futureTitle: "Solutions Built for the Future",
            forwardApproachTitle: "Forward-Thinking Approach"
          };
          const description = {
            solutionDescription: "Focusing on providing high-quality, tailored software solutions that go beyond expectations. Our agile development approach guarantees quick turnarounds and effective go-to-market strategies. We are driven by a passion for delivering exceptional value through innovative solutions and deep domain expertise.",
            problemSolvingDescription: "From the initial gathering of requirements to final deployment, our team manages every phase of the project. We conduct thorough analyses to determine the most effective solutions and apply our expertise to create scalable and reliable software.",
            collaborationDescription: "We prioritize building strong relationships with our clients. Through open communication and transparency, we foster trust and long-term partnerships. We also collaborate with leading tech providers to offer cutting-edge solutions.",
            qualityAssuranceDescription: "Quality is central to everything we do. Our strict quality management processes ensure that each project meets the highest standards. We follow industry best practices and are always focused on improving our workflows.",
            futureProofDescription: "We are committed to delivering adaptable software that evolves with your business. Our scalable solutions are designed to support long-term growth and stay relevant as your needs change.",
            forwardApproachDescription: "We as a leader in creating future-ready enterprise and web systems. We integrate scalable components that are easily adaptable to any changing environment, ensuring your software remains effective as your business grows."
          }
          this.setState({ titles, description });
    }
    render(){
        const { titles, description } = this.state
        return(    
            <div>
         {/* header */}
         <div className={'marg_all_heading_about'}>
                <h2 className="about_ser_new_tags">{titles.solutionTitle}</h2>
                        <span className="about_bor_bot_serv"></span>
                        </div>
        {/*<section>
            <div className="container-fulid">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="overall_hide">
                        <div className="custom_hide_para" id="btn_one">
                            <a className="custom_a_heading">Value Addition and Value Proposition</a>
                        </div>
                        <div className="custom_hide_para" id="btn_two">
                            <a className="custom_a_heading">Deep Analysis and Solution Delivery</a>
                        </div>
                        <div className="custom_hide_para" id="btn_three">
                            <a className="custom_a_heading">The Partnership Model</a>
                        </div>
                        <div className="custom_hide_para" id="btn_four">
                            <a className="custom_a_heading">uality Management Systems and Information Security</a>
                        </div>
                        <div className="custom_hide_para" id="btn_five">
                            <a className="custom_a_heading">Client Relationship and Trust</a>
                        </div>
                        <div className="custom_hide_paras" id="btn_six">
                            <a className="custom_a_heading">Futuristic Approach</a>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-8 ">
                       <div className="col-lg-6">
                      <div className="ocerall_ani_content">
                       <div className="custom_hide_cnt" id="circle">
                            <p className="custom_cnt">iCore Software Systems strives to fulfil the client requirements and eliminate their pain points through our seamless work ethic. Apart from meeting client requirements, we work smart to provide relevant and ample value addition along with our delivery.</p>
                            <p className="custom_cnts">This aspect of us enhances the value proposition that we offer to our clients which ensures client retention, convertible references and generate multiple streams of recurring revenue</p>

                        </div>
                        <div className="custom_hide_cnt" id="circle2">
                            <p className="custom_cnt">Our approach of handling the customer pain points involves precise requirement gathering, deep analysis and crafting tailor-made solutions. Our trait of excellence begins from requirement gathering which is obtained carefully from our client along with customer problems</p>
                            <p className="custom_cnts">A detailed analysis of the requirements gathered is conducted by our team of experts coerced with the customer problems recorded. Based on the findings of the analysis, our solution architects build carefully crafted solutions or products and deliver them to the clients</p>
                        </div>
                        <div className="custom_hide_cnt" id="circle3">
                            <p className="custom_cnt">Our values of accountability and excellence is well recognized by our clients and hence they consider us as their trusted partners in problem solving. We respect their consideration and follow the partnership model in designing solutions to them.</p>
                            <p className="custom_cnts">Due to a mutual feeling of strong partnership between us and clients, they turn to us for any kind of technology advice to cross operational hurdles with ease</p>
                        </div>
                        <div className="custom_hide_cnt" id="circle4">
                            <p className="custom_cnt">iCore Software Systems is very particular about the quality of work delivered. As one of our prime values is ‘Excellence’, we have built state of the art Quality Management Systems and follow industry’s best quality practices to keep us and our clients ahead of the game</p>
                            <p className="custom_cnts">We also follow stringent security protocols in all aspects of our functioning to honour the confidentiality of our client’s information and data that we encounter</p>
                        </div>
                        <div className="custom_hide_cnt" id="circle5">
                            <p className="custom_cnt">Strong customer focus, building customer centric solutions and our confidence in execution of our business has earned us immense trust and respect from our clients.</p>
                            <p className="custom_cnts">Our professional relationship with each and every client of us is built with the fundamentals of trust. This has enabled us to build valued client relationships and gain repetitive opportunities from clients and other references in the industry</p>
                        </div>
                        <div className="custom_hide_cnt" id="circle6">
                            <p className="custom_cnt">We strongly believe that limits of our expertise do not end at crafting solutions just as per the client requirements. But our solutions and advice extends furthermore to keep our clients equipped to meet future technological advancements.</p>
                            <p className="custom_cnts">This is possible by us due to our ability to foresee the technological advancements and deep analysis that we conduct before building the solutions</p>
                        </div>
                       </div>
                       </div>
                       <div className="col-lg-6">
                       <div className="" id="img1">
                       <img src={Philosophy_img1} className="custom_img_obp" />                       
                        </div>
                        <div className="" id="img2">
                       <img src={Philosophy_img2} className="custom_img_obp" />                       
                        </div>
                        <div className="" id="img3">
                       <img src={Philosophy_img3} className="custom_img_obp" />                       
                        </div>
                        <div className="" id="img4">
                       <img src={Philosophy_img4} className="custom_img_obp" />                       
                        </div>
                        <div className="" id="img5">
                       <img src={Philosophy_img5} className="custom_img_obp" />                       
                        </div>
                        <div className="" id="img6">
                       <img src={Philosophy_img6} className="custom_img_obp" />                       
                        </div>
                       </div>
                    </div>
                </div>
            </div>
        </section> */}
      <div className="container-fulid">
    <div className="row">
    <div className="col-lg-1"></div>
        <div className="col-lg-8">
            <div className={ 'collapsibleforabout'}>
                <h3 className={ ' txt_bor_align_about_inner1'}>{titles.valueTitle}</h3>
                <p>{description.solutionDescription}</p>
        </div>
        </div>
    </div>
    <div className="row">
    <div className="col-lg-1"></div>
        <div className="col-lg-8">
            <div className={ 'collapsibleforabout'}>
                <h3 className={ ' txt_bor_align_about_inner1'}>{titles.comprehensiveTitle}</h3>
                <p>{description.problemSolvingDescription}</p>
            </div>
        </div>
    </div>
    <div className="row">
    <div className="col-lg-1"></div>
        <div className="col-lg-8">
            <div className={ 'collapsibleforabout'} t>
                <h3 className={ ' txt_bor_align_about_inner1'}>{titles.collaborativeTitle}</h3>
                <p>{description.collaborationDescription}</p>
            </div>
        </div>
    </div>

    
    <div className="row ">
        <div className="col-lg-1"></div>
        <div className="col-lg-8">
            <div className={ 'collapsibleforabout'}>
                <h3 className={ ' txt_bor_align_about_inner1'}>{titles.qualityTitle}</h3>
                <p>{description.qualityAssuranceDescription}</p>
            </div>
        </div>
    </div>
    <div className="row">
    <div className="col-lg-1"></div>
        <div className="col-lg-8">
            <div className={ 'collapsibleforabout'}>
                <h3 className={ ' txt_bor_align_about_inner1'}>{titles.futureTitle}</h3>
                <p>{description.futureProofDescription}</p>
            </div>
        </div>
    </div>
    <div className="row">
    <div className="col-lg-1"></div>
        <div className="col-lg-8">
            <div className={ 'collapsibleforabout'}>
                <h3 className={ ' txt_bor_align_about_inner1'}>{titles.forwardApproachTitle}</h3>
                <p>{description.forwardApproachDescription}</p>
            </div>
        </div>
    </div>

</div><br/>
            </div>
        )
    }
}